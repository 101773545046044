import { useSelector } from 'react-redux';
import { getLoggedInRole, getLoggedInUser } from '../selectors/Auth';
import { ABB_UUIDS } from '../helpers/constants';

const useUserRoles = () => {
  const userRole = useSelector(getLoggedInRole);
  const loggedInUser = useSelector(getLoggedInUser);

  const isABBUser = loggedInUser?.groups?.some(g =>
    ABB_UUIDS.includes(g.groupId) && g.groupTitle === 'ABB',
  ) && loggedInUser.groups.length === 1;

  const isABBClientAdmin = isABBUser && userRole?.accessLevel === 1000;

  const isABBViewer = isABBUser && userRole?.accessLevel === 3000;

  const isEMESuperAdmin = loggedInUser?.groups.some(g =>
    ABB_UUIDS.includes(g.groupId) && g.groupTitle === 'ABB',
  ) && loggedInUser?.groups.some(g => g.groupTitle === 'EME') &&
        userRole?.accessLevel === 0;

  const isEMEClientAdmin = loggedInUser?.groups.some(g =>
    ABB_UUIDS.includes(g.groupId),
  ) && loggedInUser?.groups.some(g => g.groupTitle === 'EME') &&
        userRole?.accessLevel === 1000;

  return {
    isABBUser,
    isABBViewer,
    isABBClientAdmin,
    isEMESuperAdmin,
    isEMEClientAdmin,
  };
};

export default useUserRoles;
