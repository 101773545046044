import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View as ViewType } from '../../../types/View';
import { useHistory } from 'react-router-dom';
import c, { cardTypes, EntryStatus, tulipsAdminCardTypes } from '../../../helpers/constants';
import { createDuplicateView, fetchDuplicateViews, transferEntries } from '../../../actions/View';
import { fetchEntries, updateEntryStatus } from '../../../actions/Entry';
import { getEntries, isFetchingEntries } from '../../../selectors/Entry';
import { formatUrl, latLngCalculation } from '../../../helpers/functions';
import Button from '../../../components/Button';
import { getAllEntryFields } from '../../../selectors/EntryField';
import { getLoggedInRole, getLoggedInUser } from '../../../selectors/Auth';
import MaterialMap from '../../../components/MaterialMap';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import DropDown from '../../../components/DropDown';
import Card from '../../../components/Card/Card';
import useShortcodes from '../../../hooks/ShortcodeHook';
import DataTable from '../../../components/DataTable';
import { fetchAllEntryFields } from '../../../actions/EntryField';
import { getDuplicateViews } from '../../../selectors/View';
import { toNumber } from 'lodash';
import ButtonToggler from '../../../components/ButtonToggler';
import assetGallery from '../../../components/AssetGallery';
import { Entry } from '../../../types/Entry';
import { Alert } from 'react-bootstrap';
import { getActiveConfiguration } from '../../../selectors/Configuration';
import { fetchClients } from '../../../actions/Client';
import { getClients } from '../../../selectors/Client';
import { fetchGroups } from '../../../actions/Group';
import { getGroups } from '../../../selectors/Group';

type Props = {
  view: ViewType;
  isLoadingDatatable: boolean;
};

function DynamicView(props: Props) {
  const dispatch = useDispatch();
  const { view, isLoadingDatatable } = props;
  const entries = useSelector(getEntries);
  const clients = useSelector(getClients);
  const groups = useSelector(getGroups);
  const isLoadingEntries = useSelector(isFetchingEntries);
  const entryFields = useSelector(getAllEntryFields);
  const activeConfiguration = useSelector(getActiveConfiguration);
  const userRole = useSelector(getLoggedInRole);
  const loggedInUser = useSelector(getLoggedInUser);
  const duplicateViews = useSelector(getDuplicateViews);
  const [gridView, setGridView] = useState(true);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [belongsToTulips, setBelongsToTulips] = useState(false);
  const [privateZoom, setPrivateZoom] = useState(5);
  const [publicZoom, setPublicZoom] = useState(5);
  const [privateLat, setPrivateLat] = useState(52.1312863);
  const [publicLat, setPublicLat] = useState(52.1312863);
  const [privateLng, setPrivateLng] = useState(-0.0544277);
  const [publicLng, setPublicLng] = useState(-0.0544277);
  const [transferModal, setTransferModal] = useState(false);
  const [toggleSelectable, setToggleSelectable] = useState(false);
  const [toggleCreateView, setToggleCreateView] = useState(false);
  const [selectedEntryIds, setSelectedEntryIds] = useState<string[]>([]);
  const [newFormTitle, setNewFormTitle] = useState('');
  const [newFormDescription, setNewFormDescription] = useState('');
  const [toViewId, setToViewId] = useState('');
  const [accessLevelModal, setAccessLevel] = useState(2000);
  const [statusModal, setStatus] = useState('Private');
  const [isMadeByMeModal, setIsMadeByMe] = useState(false);
  const [entryIdModal, setEntryId] = useState('');
  const [formIdModal, setFormId] = useState('');
  const [showCopied, setShowCopied] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const [selectedClient, setSelectedClient] = useState(view.clientId);
  const [selectedGroup, setSelectedGroup] = useState(view.groupId);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [itemsViews, setItemsViews] = useState(duplicateViews?.data.map(r => ({ label: r.title, value: r.formId })) || []);

  const [selectedDuplicableGroup, setSelectedDuplicableGroup] = useState('');
  const [selectedDuplicableClient, setSelectedDuplicableClient] = useState('');
  const [duplicableGroups, setDuplicableGroups] = useState(duplicateViews?.data.map(r => ({ label: r.groupTitle, value: r.groupId })) || []);
  const [duplicableClients, setDuplicableClients] = useState(duplicateViews?.data.map(r => ({ label: r.clientTitle, value: r.clientId })) || []);

  //Map related functionalities
  const publicStatuses = [
    EntryStatus.PUBLIC,
    EntryStatus.REUSED,
    EntryStatus.EXPIRED,
    EntryStatus.SOLD,
  ];

  const mapPublicItems = entryFields?.data.filter(x => (x.fieldType == 'map' && (publicStatuses.includes(x.entryStatus as EntryStatus) || x.createdBy == loggedInUser?.email) && x.archived == null && x.entryFormId == view?.formId)).map(e => ({
    'geolocation': e.value,
    'id': e.entryId,
    'title': e.entryTitle,
    'description': e.entryDescription,
    'assetId': e.entryAssetId,
  }));

  const mapPrivateItems = entryFields?.data.filter(x => x.fieldType == 'map' && x.archived == null && x.entryFormId == view?.formId).map(e => ({
    'geolocation': e.value,
    'id': e.entryId,
    'title': e.entryTitle,
    'description': e.entryDescription,
    'assetId': e.entryAssetId,
  }));

  const {
    groupShortcode,
    clientShortcode,
    isUsingShortcodes,
  } = useShortcodes(c.APP_ROUTES.VIEW_RENDERER_SHORTCODE, c.APP_ROUTES.VIEW_RENDERER);

  useEffect(() => {
    if (isUsingShortcodes && groupShortcode && clientShortcode){
      dispatch(fetchAllEntryFields({ fetchParams: { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode } }));
    } else {
      dispatch(fetchAllEntryFields());
    }
  }, [dispatch, entries]);

  // transfer entries related fetches
  useEffect(() => {
    if (loggedInUser && userRole?.accessLevel === 0){
      dispatch(fetchDuplicateViews(view.formId));
      dispatch(fetchClients());
      dispatch(fetchGroups());
    }
  }, [loggedInUser, userRole]);
  
  useEffect(() => {
    if (loggedInUser && userRole?.accessLevel === 0){
      setSelectedClient(view.clientId);
      setSelectedGroup(view.groupId);
    }
  }, [view]);

  useEffect(() => {
    if (loggedInUser && userRole?.accessLevel === 0) {
      setDuplicableGroups(
        duplicateViews?.data
          .map(r => ({ label: r.groupTitle, value: r.groupId }))
          .filter((item, index, self) =>
            index === self.findIndex(i => i.value === item.value),
          ) || [],
      );

      setDuplicableClients(
        duplicateViews?.data
          .map(r => ({ label: r.clientTitle, value: r.clientId }))
          .filter((item, index, self) =>
            index === self.findIndex(i => i.value === item.value),
          ) || [],
      );
    }
  }, [duplicateViews]);

  // Set default lat lng and zoom based on the first card, also reset this value when the user is clicking in another view
  useEffect(() => {
    if (mapPublicItems && mapPrivateItems) {
      const mapCalc = latLngCalculation(mapPrivateItems, mapPublicItems);
      setPublicLat(mapCalc.publicLat);
      setPrivateLat(mapCalc.privateLat);
      setPublicLng(mapCalc.publicLng);
      setPrivateLng(mapCalc.privateLng);
    }
    setPublicZoom(5);
    setPrivateZoom(5);
  }, [gridView, view]);

  const history = useHistory();

  const onNavigate = (entryId: string) => {
    if (!groupShortcode && !clientShortcode) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER, entryId));
    } else if (groupShortcode && clientShortcode) {
      history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, groupShortcode, clientShortcode, entryId));
    }
  };


  //Function that retrieves active locations on marker click
  const activeCardLocation = (geo: any) => {
    if (geo) {
      setPrivateLat(parseFloat(geo.split('~')[0]));
      // @ts-ignore
      setPrivateLng(parseFloat(geo.split('~')[1]));
      // @ts-ignore
      setPublicLat(parseFloat(geo.split('~')[0]));
      // @ts-ignore
      setPublicLng(parseFloat(geo.split('~')[1]));
      setPrivateZoom(8);
      setPublicZoom(8);
    }

  };


  const toggleTransferEntries = () => {
    setToggleSelectable(!toggleSelectable);
  };

  const toggleTransferModal = () => {
    setTransferModal(true);
  };

  const toggleCreateViewModal = () => {
    setToggleCreateView(true);
  };

  const onDismiss = () => {
    setTransferModal(false);
  };
  const onDismissStatusModal = () => {
    setOpenStatusModal(false);
  };

  const onDismissViewModal = () => {
    setToggleCreateView(false);
  };

  const createViewAndForm = () => {
    if (view) {
      dispatch(createDuplicateView(selectedGroup ?? view?.groupId, selectedClient ?? view?.clientId, view?.formId, newFormTitle, newFormDescription));
      setToggleCreateView(false);
    }
  };

  const onStatusSelect = (obj: any, entryId: string, formId: string) => {
    dispatch(updateEntryStatus(entryId, obj, formId));
    setOpenStatusModal(false);
  };

  const finalizeEntryTransfer = async () => {
    if (view && selectedEntryIds && selectedEntryIds.length > 0) {
      await transferEntries(selectedDuplicableGroup, selectedDuplicableClient, selectedEntryIds, toViewId);
      setTransferModal(false);
      setRefetch(!refetch);
    }
  };


  const setStatusModal = (accessLevel: number, status: string, isMadeByMe: boolean, entryId: string, formId: string, belongsToTulipsClient: boolean ) => {
    setAccessLevel(accessLevel);
    setStatus(status);
    setIsMadeByMe(isMadeByMe);
    setEntryId(entryId);
    setFormId(formId);
    setOpenStatusModal(true);
    setBelongsToTulips(belongsToTulipsClient);
  };
  const itemsStatus = cardTypes.map(r => ({ label: r.label, value: r.value }));
  const itemsStatusTulipsAdmin = tulipsAdminCardTypes.map(r => ({ label: r.label, value: r.value }));

  const handleCardSelect = (entryId: string) => {
    if (selectedEntryIds.includes(entryId)) {
      setSelectedEntryIds(selectedEntryIds.filter(id => id !== entryId));
    } else {
      setSelectedEntryIds([...selectedEntryIds, entryId]);
    }
  };

  const onViewSelect = (obj: any) => {
    setToViewId(obj.value);
  };

  const onGroupSelect = (obj: any) => {
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setSelectedGroup(obj.value);
  };

  const onClientSelect = (obj: any) => {
    setSelectedClient(obj.value);
  };

  const onDuplicableGroupSelect = (obj: any) => {
    setSelectedDuplicableClient('');
    setDuplicableClients(
      duplicateViews?.data
        .filter(data => data.groupId.includes(obj.value)) // Existing filter
        .map(r => ({ label: r.clientTitle, value: r.clientId })) // Map to desired structure
        .filter((item, index, self) =>
          index === self.findIndex(i => i.value === item.value), // Deduplicate by value
        ) || [],
    );
    setSelectedDuplicableGroup(obj.value);
  };

  const onDuplicableClientSelect = (obj: any) => {
    setItemsViews(duplicateViews?.data.filter(data => data.clientId.includes(obj.value)).map(r => ({ label: r.title, value: r.formId })) || []);
    setSelectedDuplicableClient(obj.value);
  };

  const selectAllEntries = () => {
    if (entries?.data) {
      if (selectedEntryIds.length === 0){
        setSelectedEntryIds(entries.data.map(entry => entry.id));
      } else {
        setSelectedEntryIds([]);
      }
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id }));

  const toEntryEdit = (entryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.FORM_RENDERER_EDIT, entryId));
  };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30, 50, 100],
  };

  const onShare = (row: Entry) => {
    const rootUrl = window.location.origin;
    const relativeUrl = formatUrl(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, row.groupShortcode, row.clientShortcode, row.id);
    const fullUrl = `${rootUrl}${relativeUrl}`;

    navigator.clipboard.writeText(fullUrl);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 4000);
  };

  return (
      <>
        {showCopied && <Alert className="mt-3" variant="success">
          Guest link copied to clipboard successfully!
        </Alert>}
      <div className="row">
        {gridView &&
          <div className='col-12'>
            {userRole?.accessLevel === 0 &&
                <>
              <Button title={'Select'} onClick={toggleTransferEntries}/>
              {'   '}
              <Button title={'Transfer'} disabled={selectedEntryIds?.length === 0} onClick={toggleTransferModal}/>
                  {'   '}
              {toggleSelectable && <Button title={selectedEntryIds?.length === 0 ? 'Select All' : 'Deselect All'} onClick={selectAllEntries}/>}
            </>}
            <DataTable
                paging={entries?.paging}
                isLoading={isLoadingDatatable || isLoadingEntries}
                data={entries?.data}
                config={config}
                fetchFunction={fetchEntries}
                dependencies={refetch}
                fetchParams={isUsingShortcodes && { 'groupShortcode': groupShortcode, 'clientShortcode': clientShortcode }}
                baseSearch={{ 'formId': view?.formId }}
                isTable={false}
                gridView={gridView}
                togglerFunc={() => setGridView((prev) => !prev)}
                lineItemTemplate={(row: any) => (
                    <div key={row.index} className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>
                      <Card
                          onClick={() => onNavigate(row.id)}
                          onShare={() => onShare(row)}
                          cardId={row.index}
                          title={row.title}
                          description={row.description}
                          isSelected={selectedEntryIds.includes(row.id)}
                          status={userRole && userRole?.accessLevel <= 1000 ? row.status : activeConfiguration?.reservedStatus  ? row.status : null}
                          itemId={row.id}
                          onEdit={(!isUsingShortcodes && userRole && userRole?.accessLevel < 3000) ? () => toEntryEdit(row.id) : undefined}
                          onStatusClick={() => userRole && userRole?.accessLevel <= 1000 ? setStatusModal(toNumber(userRole?.accessLevel), row.status, row.createdBy == loggedInUser?.id, row.id, view?.formId, activeConfiguration?.reservedStatus ?? false ) : null}
                          imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                          selectable={toggleSelectable}
                          onSelect={handleCardSelect}
                      />
                    </div>
                )}
            />
          </div>
        }
        {!gridView &&
              <div className="col-12">
                <div className="view_map">
                  <div className="d-flex flex-column align-items-end mb-3">
                    <div className='view__map-grid--size'>
                      <ButtonToggler grid={gridView} title1={'Grid View'} title2={'Map View'} img1={gridView ? assetGallery.gridViewActive : assetGallery.gridView} img2={gridView ? assetGallery.mapView : assetGallery.mapViewActive} onClick={()=>setGridView(!gridView)}/>
                    </div>
                  </div>
                  <MaterialMap
                      lat={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicLat : privateLat}
                      lng={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicLng : privateLng}
                      view={'viewRenderer'}
                      zoom={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? publicZoom : privateZoom}
                      mapList={(userRole && userRole?.accessLevel >= 2000 || (groupShortcode && clientShortcode)) ? mapPublicItems : mapPrivateItems}
                      data ={entries?.data}
                      enableInfoWindow={true}
                      activeCardLocation={activeCardLocation}
                  />
                </div>
              </div>
          }
      </div>
        <Modal show={transferModal}
                   title={toggleCreateView ? 'Create New Form & View' : 'Transfer Entries'}
                   onHide={onDismiss}>
          {!toggleCreateView && (
              <>
                {duplicableGroups.length === 0 && duplicableClients.length === 0 ? (
                    <div className="mb-4">
                      No forms exist with the same fields as the current view form. Please create a duplicate view from below.
                    </div>
                ) : (
                    <>
                      <DropDown
                          items={duplicableGroups}
                          value={selectedDuplicableGroup}
                          onSelect={onDuplicableGroupSelect}
                      />
                      <DropDown
                          items={duplicableClients}
                          value={selectedDuplicableClient}
                          onSelect={onDuplicableClientSelect}
                          disabled={!selectedDuplicableGroup}
                      />
                      {selectedDuplicableClient ? (
                        itemsViews.length > 0 ? (
                              <DropDown
                                  id="viewId"
                                  placeholder="Select the view to transfer selected entries to"
                                  items={itemsViews}
                                  value={toViewId}
                                  onSelect={onViewSelect}
                                  type="default"
                              />
                        ) : (
                              <div className="mb-4">
                                No duplicate forms exist for the currently selected client. Please create a duplicate form or select a different client.
                              </div>
                        )
                      ) : null}
                    </>
                )}
              </>
          )}

          {!toggleCreateView && <>
                    <Button title="Create New Form & View" onClick={toggleCreateViewModal}/>
                    {' '}
                    <Button title="Complete Transfer" disabled={!toViewId} onClick={finalizeEntryTransfer}/>
                </>}
                {toggleCreateView && <>
                  <DropDown items={itemsGroups} value={selectedGroup} onSelect={onGroupSelect}/>
                  <DropDown items={itemsClients} value={selectedClient} onSelect={onClientSelect}/>
                  <Input name="title"
                           id="title"
                           label="Title"
                           required
                           value={newFormTitle}
                           onChange={setNewFormTitle}
                           onBlur={setNewFormTitle}
                           placeholder="Insert the title of the new view"
                    />
                    <Input name="description"
                           id="description"
                           label="Description"
                           required
                           value={newFormDescription}
                           onChange={setNewFormDescription}
                           onBlur={setNewFormDescription}
                           placeholder="Insert a description for the new view"
                    />
                    <Button title="Create View" disabled={!newFormTitle || !newFormDescription}
                            onClick={createViewAndForm}/>
                    {' '}
                    <Button title="Cancel" onClick={onDismissViewModal}/>
                </>}
            </Modal>
        <Modal show={openStatusModal} title={'Choose Status'} onHide={onDismissStatusModal}>
          {
          ((accessLevelModal < 2000 || isMadeByMeModal) && !belongsToTulips) &&
          <div><DropDown placeholder="Badge" type="default" items={itemsStatus}
                      value={statusModal} onSelect={(e) => onStatusSelect( e.value, entryIdModal, formIdModal)}/>
          </div>
        }
          {
              belongsToTulips ?
              <div><DropDown placeholder="Badge" type="default" items={ itemsStatusTulipsAdmin }
                             value={statusModal} onSelect={(e) => onStatusSelect( e.value, entryIdModal, formIdModal)}/>
              </div> : null
          }

          </Modal>
        </>
  );
}

export default DynamicView;
