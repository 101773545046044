import React from 'react';
import { Switch, Route } from 'react-router-dom';
import c from '../../helpers/constants';

import Dashboard from '../../views/Dashboard';
import Error404 from '../../views/Error404';
import RoleDataTable from '../../views/admin/Role/RoleDataTable';
import RoleForm from '../../views/admin/Role/RoleForm';
import RoleGroupDataTable from '../../views/admin/RoleGroup/RoleGroupDataTable';
import RoleGroupForm from '../../views/admin/RoleGroup/RoleGroupForm';
import RoleClientDataTable from '../../views/admin/RoleClient/RoleClientDataTable';
import RoleClientForm from '../../views/admin/RoleClient/RoleClientForm';
import UserDataTable from '../../views/admin/User/UserDataTable';
import UserForm from '../../views/admin/User/UserForm';
import UserLogin from '../../views/admin/User/UserLogin';
import UserRegister from '../../views/admin/User/UserRegister';
import UserPendingVerification from '../../views/admin/User/UserPendingVerification';
import RequestResetPassword from '../../views/admin/User/RequestResetPassword';
import ResetPassword from '../../views/admin/User/ResetPassword';
import Profile from '../../views/profile/UserProfile';
import GroupDataTable from '../../views/admin/Group/GroupDataTable';
import GroupForm from '../../views/admin/Group/GroupForm';
import ClientDataTable from '../../views/admin/Client/ClientDataTable';
import ClientForm from '../../views/admin/Client/ClientForm';
import CategoryDataTable from '../../views/admin/Category/CategoryDataTable';
import CategoryForm from '../../views/admin/Category/CategoryForm';
import FieldDataTable from '../../views/admin/Field/FieldDataTable';
import FieldForm from '../../views/admin/Field/FieldForm';
import FieldPropertyDataTable from '../../views/admin/FieldProperty/FieldPropertyDataTable';
import FieldPropertyForm from '../../views/admin/FieldProperty/FieldPropertyForm';
import FieldTypeDataTable from '../../views/admin/FieldType/FieldTypeDataTable';
import FieldTypeForm from '../../views/admin/FieldType/FieldTypeForm';
import FieldTypeOptionDataTable from '../../views/admin/FieldTypeOption/FieldTypeOptionDataTable';
import FieldTypeOptionForm from '../../views/admin/FieldTypeOption/FieldTypeOptionForm';
import AssetDataTable from '../../views/admin/Asset/AssetDataTable';
import AssetForm from '../../views/admin/Asset/AssetForm';
import EntryDataTable from '../../views/admin/Entry/EntryDataTable';
import EntryForm from '../../views/admin/Entry/EntryForm';
import EntryFieldDataTable from '../../views/admin/EntryField/EntryFieldDataTable';
import EntryFieldForm from '../../views/admin/EntryField/EntryFieldForm';
import FormDataTable from '../../views/admin/Form/FormDataTable';
import FormForm from '../../views/admin/Form/FormForm';
import FormFieldDataTable from '../../views/admin/FormField/FormFieldDataTable';
import FormFieldForm from '../../views/admin/FormField/FormFieldForm';
import FormFieldRoleDataTable from '../../views/admin/FormFieldRole/FormFieldRoleDataTable';
import FormFieldRoleForm from '../../views/admin/FormFieldRole/FormFieldRoleForm';
import ViewDataTable from '../../views/admin/View/ViewDataTable';
import ViewForm from '../../views/admin/View/ViewForm';
import ViewRoleDataTable from '../../views/admin/ViewRole/ViewRoleDataTable';
import ViewRoleForm from '../../views/admin/ViewRole/ViewRoleForm';
import ViewRenderer from '../../views/renderers/View/ViewRenderer';
import EntryRenderer from '../../views/renderers/EntryRenderer';
import FormRenderer from '../../views/renderers/FormRenderer';
import MarketplacesViewRenderer from '../../views/Marketplace/MarketplaceHub';
import EntryEdgeDataTable from '../../views/admin/EntryEdge/EntryEdgeDataTable';
import EntryEdgeForm from '../../views/admin/EntryEdge/EntryEdgeForm';
import ConfigurationDataTable from '../../views/admin/Configuration/ConfigurationDataTable';
import ConfigurationForm from '../../views/admin/Configuration/ConfigurationForm';
import EntryHistoryDataTable from '../../views/admin/EntryHistory/EntryHistoryDataTable';
import EntryFieldHistoryDataTable from '../../views/admin/EntryFieldHistory/EntryFieldHistoryDataTable';

import BadgeDataTable from '../../views/admin/Badge/BadgeDataTable';
import BadgeForm from '../../views/admin/Badge/BadgeForm';

import SolutionBadgeDataTable from '../../views/admin/SolutionBadge/SolutionBadgeDataTable';
import SolutionBadgeForm from '../../views/admin/SolutionBadge/SolutionBadgeForm';

import SolutionInputTagDataTable from '../../views/admin/SolutionInputTag/SolutionInputTagDataTable';
import SolutionInputTagForm from '../../views/admin/SolutionInputTag/SolutionInputTagForm';

import SolutionDataTable from '../../views/admin/Solution/SolutionDataTable';
import SolutionForm from '../../views/admin/Solution/SolutionForm';

import EntryTagDataTable from '../../views/admin/EntryTag/EntryTagDataTable';
import EntryTagForm from '../../views/admin/EntryTag/EntryTagForm';

import TagDataTable from '../../views/admin/Tag/TagDataTable';
import TagForm from '../../views/admin/Tag/TagForm';

import MatchDataTable from '../../views/admin/Match/MatchDataTable';
import MatchForm from '../../views/admin/Match/MatchForm';

import SolutionOutputTagDataTable from '../../views/admin/SolutionOutputTag/SolutionOutputTagDataTable';
import SolutionOutputTagForm from '../../views/admin/SolutionOutputTag/SolutionOutputTagForm';

import SolutionFunctionDataTable from '../../views/admin/SolutionFunction/SolutionFunctionDataTable';
import SolutionFunctionForm from '../../views/admin/SolutionFunction/SolutionFunctionForm';

import MaterialsFunctionDataTable from '../../views/admin/MaterialsFunction/MaterialsFunctionDataTable';
import MaterialsFunctionForm from '../../views/admin/MaterialsFunction/MaterialsFunctionForm';
import ImpactOverview from '../../views/MatchOverview/ImpactOverview';
import ValuationDashboard from '../../views/MatchOverview/ValuationDashboard';
import TimelineWidget from '../../views/widgets/TimelineWidget';
import Verification from '../../views/Verification/Verification';

import ShadowEntryDataTable from '../../views/admin/ShadowEntry/ShadowEntryDataTable';
import ShadowEntryForm from '../../views/admin/ShadowEntry/ShadowEntryForm';

import MarketplaceDataTable from '../../views/admin/Marketplace/MarketplaceDataTable';
import MarketplaceForm from '../../views/admin/Marketplace/MarketplaceForm';
import MarketplaceEntryDataTable from '../../views/admin/MarketplaceEntry/MarketplaceEntryDataTable';
import MarketplaceEntryForm from '../../views/admin/MarketplaceEntry/MarketplaceEntryForm';
import Marketplace from '../../views/Marketplace/Marketplace';
import MarketplaceClientAdminForm from '../../views/admin/Marketplace/MarketplaceClientAdminForm';

import ClientProfile from '../../views/profile/ClientProfile';
import AuctionDataTable from '../../views/admin/Auction/AuctionDataTable';
import AuctionForm from '../../views/admin/Auction/AuctionForm';
import AuctionBidForm from '../../views/admin/AuctionBid/AuctionBidForm';
import AuctionBidDataTable from '../../views/admin/AuctionBid/AuctionBidDataTable';
import AuctionEntryDataTable from '../../views/admin/AuctionEntry/AuctionEntryDataTable';
import AuctionEntryForm from '../../views/admin/AuctionEntry/AuctionEntryForm';
import Auction from '../../views/Auction/Auction';
import AuctionHub from '../../views/Auction/AuctionHub';
import AuctionEntryRenderer from '../../views/Auction/AuctionEntry';
import AuctionStepOne from '../../views/Auction/AuctionWizard/StepOne';
import AuctionStepTwo from '../../views/Auction/AuctionWizard/StepTwo';
import AuctionStepThree from '../../views/Auction/AuctionWizard/StepThree';
import AuctionStepFour from '../../views/Auction/AuctionWizard/StepFour';
import MarketplaceStepOne from '../../views/Marketplace/MarketplaceWizard/StepOne';
import MarketplaceStepTwo from '../../views/Marketplace/MarketplaceWizard/StepTwo';
import MarketplaceStepThree from '../../views/Marketplace/MarketplaceWizard/StepThree';

import SearchEngine from '../../views/SearchEngine/SearchEngine';
import PopularSearch from '../../views/admin/PopularSearch/PopularSearchForm';
import PopularSearchDataTable from '../../views/admin/PopularSearch/PopularSearchDataTable';
import LandingPage from '../../views/LandingPage/LandingPage';
import SearchResults from '../../views/LandingPage/SearchResults';
import EntryPublicRenderer from '../../views/renderers/EntryPublicRenderer';

import LeadDataTable from '../../views/admin/Lead/LeadDataTable';
import LeadForm from '../../views/admin/Lead/LeadForm';
import LeadRegistrationForm from '../../views/admin/Lead/LeadRegistrationForm';
import StaticPageView from '../../views/StaticPages/StaticPageView';
import StatusCodeDataTable from '../../views/admin/StatusCode/StatusCodeDataTable';

import ContactDataTable from '../../views/admin/Contact/ContactDataTable';
import ContactForm from '../../views/admin/Contact/ContactForm';

import CompositionDataTable from '../../views/admin/Composition/CompositionDataTable';
import CompositionForm from '../../views/admin/Composition/CompositionForm';

import EntryCompositionDataTable from '../../views/admin/EntryComposition/EntryCompositionDataTable';
import EntryCompositionForm from '../../views/admin/EntryComposition/EntryCompositionForm';

import OfferDataTable from '../../views/admin/Offer/OfferDataTable';
import OfferForm from '../../views/admin/Offer/OfferForm';

import OfferItemDataTable from '../../views/admin/OfferItem/OfferItemDataTable';
import OfferItemForm from '../../views/admin/OfferItem/OfferItemForm';

import OfferProjectManagerDataTable from '../../views/admin/OfferProjectManager/OfferProjectManagerDataTable';
import OfferProjectManagerForm from '../../views/admin/OfferProjectManager/OfferProjectManagerForm';

import OfferStatusCodeDataTable from '../../views/admin/OfferStatusCode/OfferStatusCodeDataTable';
import OfferStatusCodeForm from '../../views/admin/OfferStatusCode/OfferStatusCodeForm';

import DealHub from '../../views/DealHub/MyProposals';
import ABBOfferForm from '../../views/DealHub/CreateOffer';
import ABBEstimationForm from '../../views/DealHub/CreateEstimation';
import ABBReviewEstimation from '../../views/DealHub/ReviewEstimation';

import OfferItemStatusCodeDataTable from '../../views/admin/OfferItemStatusCode/OfferItemStatusCodeDataTable';
import OfferItemStatusCodeForm from '../../views/admin/OfferItemStatusCode/OfferItemStatusCodeForm';

import Shipment from '../../views/DealHub/Shipment';
import Subscription from '../../views/Subscription/Subscription';
import PaymentStatusPage from '../../views/Subscription/Return';
import PlanDataTable from '../../views/admin/Plan/PlanDataTable';
import PlanForm from '../../views/admin/Plan/PlanForm';
import SubscriptionDataTable from '../../views/admin/Subscription/SubscriptionDataTable';
import SubscriptionForm from '../../views/admin/Subscription/SubscriptionForm';
import TransactionDataTable from '../../views/admin/Transaction/TransactionDataTable';
import TransactionForm from '../../views/admin/Transaction/TransactionForm';
import OPPNotificationDataTable from '../../views/admin/OPPNotification/OPPNotificationDataTable';
import GreenReport from '../Offer/GreenReport/GreenReport';
import { ContextProvider } from '../../providers/ContextProvider';


const Router = () => {
  return (
      <ContextProvider>
        <Switch>
            <Route path={c.APP_ROUTES.TIMELINE_ENTRY_RENDERER_SHORTCODE} exact component={TimelineWidget}/>
            <Route path={c.APP_ROUTES.ROLE} exact component={RoleDataTable}/>
            <Route path={c.APP_ROUTES.ROLE_CREATE} exact component={RoleForm}/>
            <Route path={c.APP_ROUTES.ROLE_UPDATE} exact component={RoleForm}/>
            <Route path={c.APP_ROUTES.ROLE_GROUP} exact component={RoleGroupDataTable}/>
            <Route path={c.APP_ROUTES.ROLE_GROUP_CREATE} exact component={RoleGroupForm}/>
            <Route path={c.APP_ROUTES.ROLE_CLIENT} exact component={RoleClientDataTable}/>
            <Route path={c.APP_ROUTES.ROLE_CLIENT_CREATE} exact component={RoleClientForm}/>
            <Route path={c.APP_ROUTES.USER} exact component={UserDataTable}/>
            <Route path={c.APP_ROUTES.USER_LOGIN} exact component={UserLogin}/>
            <Route path={c.APP_ROUTES.USER_CREATE} exact component={UserForm}/>
            <Route path={c.APP_ROUTES.USER_UPDATE} exact component={UserForm}/>
            <Route path={c.APP_ROUTES.USER_REGISTER} exact component={UserRegister}/>
            <Route path={c.APP_ROUTES.USER_PENDING_VERIFICATION} exact component={UserPendingVerification}/>
            <Route path={c.APP_ROUTES.FORGOT_PASSWORD} exact component={RequestResetPassword}/>
            <Route path={c.APP_ROUTES.RESET_PASSWORD} exact component={ResetPassword}/>
            <Route path={c.APP_ROUTES.PROFILE} exact component={Profile}/>
            <Route path={c.APP_ROUTES.GROUP} exact component={GroupDataTable}/>
            <Route path={c.APP_ROUTES.GROUP_CREATE} exact component={GroupForm}/>
            <Route path={c.APP_ROUTES.GROUP_UPDATE} exact component={GroupForm}/>
            <Route path={c.APP_ROUTES.CLIENT} exact component={ClientDataTable}/>
            <Route path={c.APP_ROUTES.CLIENT_CREATE} exact component={ClientForm}/>
            <Route path={c.APP_ROUTES.CLIENT_UPDATE} exact component={ClientForm}/>
            <Route path={c.APP_ROUTES.CLIENT_PROFILE} exact component={ClientProfile}/>
            <Route path={c.APP_ROUTES.CLIENT_PROFILE_SHORTCODE} exact component={ClientProfile}/>
            <Route path={c.APP_ROUTES.CATEGORY} exact component={CategoryDataTable}/>
            <Route path={c.APP_ROUTES.CATEGORY_CREATE} exact component={CategoryForm}/>
            <Route path={c.APP_ROUTES.CATEGORY_UPDATE} exact component={CategoryForm}/>
            <Route path={c.APP_ROUTES.FIELD} exact component={FieldDataTable}/>
            <Route path={c.APP_ROUTES.FIELD_CREATE} exact component={FieldForm}/>
            <Route path={c.APP_ROUTES.FIELD_UPDATE} exact component={FieldForm}/>
            <Route path={c.APP_ROUTES.FIELD_PROPERTY} exact component={FieldPropertyDataTable}/>
            <Route path={c.APP_ROUTES.FIELD_PROPERTY_CREATE} exact component={FieldPropertyForm}/>
            <Route path={c.APP_ROUTES.FIELD_PROPERTY_UPDATE} exact component={FieldPropertyForm}/>
            <Route path={c.APP_ROUTES.FIELD_TYPE} exact component={FieldTypeDataTable}/>
            <Route path={c.APP_ROUTES.FIELD_TYPE_CREATE} exact component={FieldTypeForm}/>
            <Route path={c.APP_ROUTES.FIELD_TYPE_UPDATE} exact component={FieldTypeForm}/>
            <Route path={c.APP_ROUTES.FIELD_TYPE_OPTION} exact component={FieldTypeOptionDataTable}/>
            <Route path={c.APP_ROUTES.FIELD_TYPE_OPTION_CREATE} exact component={FieldTypeOptionForm}/>
            <Route path={c.APP_ROUTES.FIELD_TYPE_OPTION_UPDATE} exact component={FieldTypeOptionForm}/>
            <Route path={c.APP_ROUTES.ENTRY} exact component={EntryDataTable}/>
            <Route path={c.APP_ROUTES.ENTRY_CREATE} exact component={EntryForm}/>
            <Route path={c.APP_ROUTES.ENTRY_UPDATE} exact component={EntryForm}/>
            <Route path={c.APP_ROUTES.ENTRY_FIELD} exact component={EntryFieldDataTable}/>
            <Route path={c.APP_ROUTES.ENTRY_FIELD_CREATE} exact component={EntryFieldForm}/>
            <Route path={c.APP_ROUTES.ENTRY_FIELD_UPDATE} exact component={EntryFieldForm}/>
            <Route path={c.APP_ROUTES.ASSET} exact component={AssetDataTable}/>
            <Route path={c.APP_ROUTES.ASSET_CREATE} exact component={AssetForm}/>
            <Route path={c.APP_ROUTES.ASSET_UPDATE} exact component={AssetForm}/>
            <Route path={c.APP_ROUTES.FORM} exact component={FormDataTable}/>
            <Route path={c.APP_ROUTES.FORM_CREATE} exact component={FormForm}/>
            <Route path={c.APP_ROUTES.FORM_UPDATE} exact component={FormForm}/>
            <Route path={c.APP_ROUTES.FORM_FIELD} exact component={FormFieldDataTable}/>
            <Route path={c.APP_ROUTES.FORM_FIELD_CREATE} exact component={FormFieldForm}/>
            <Route path={c.APP_ROUTES.FORM_FIELD_UPDATE} exact component={FormFieldForm}/>
            <Route path={c.APP_ROUTES.FORM_FIELD_ROLE} exact component={FormFieldRoleDataTable}/>
            <Route path={c.APP_ROUTES.FORM_FIELD_ROLE_CREATE} exact component={FormFieldRoleForm}/>
            <Route path={c.APP_ROUTES.VIEW} exact component={ViewDataTable}/>
            <Route path={c.APP_ROUTES.VIEW_CREATE} exact component={ViewForm}/>
            <Route path={c.APP_ROUTES.VIEW_UPDATE} exact component={ViewForm}/>
            <Route path={c.APP_ROUTES.VIEW_ROLE} exact component={ViewRoleDataTable}/>
            <Route path={c.APP_ROUTES.VIEW_ROLE_CREATE} exact component={ViewRoleForm}/>
            <Route path={c.APP_ROUTES.VIEW_RENDERER} exact component={ViewRenderer}/>
            <Route path={c.APP_ROUTES.ENTRY_RENDERER} exact component={EntryRenderer}/>
            <Route path={c.APP_ROUTES.ENTRY_RENDERER_PUBLIC} exact component={EntryPublicRenderer}/>
            <Route path={`${c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE}?QRControl=true`} exact component={EntryRenderer}/>
            <Route path={c.APP_ROUTES.FORM_RENDERER} exact component={FormRenderer}/>
            <Route path={c.APP_ROUTES.FORM_RENDERER_EDIT} exact component={FormRenderer}/>
            <Route path={c.APP_ROUTES.ENTRY_EDGE} exact component={EntryEdgeDataTable}/>
            <Route path={c.APP_ROUTES.ENTRY_EDGE_CREATE} exact component={EntryEdgeForm}/>
            <Route path={c.APP_ROUTES.CONFIGURATION} exact component={ConfigurationDataTable}/>
            <Route path={c.APP_ROUTES.CONFIGURATION_UPDATE} exact component={ConfigurationForm}/>
            <Route path={c.APP_ROUTES.CONFIGURATION_CREATE} exact component={ConfigurationForm}/>
            <Route path={c.APP_ROUTES.ENTRIES_HISTORY} exact component={EntryHistoryDataTable}/>
            <Route path={c.APP_ROUTES.ENTRY_FIELDS_HISTORY} exact component={EntryFieldHistoryDataTable}/>

            <Route path={c.APP_ROUTES.BADGE} exact component={BadgeDataTable}/>
            <Route path={c.APP_ROUTES.BADGE_UPDATE} exact component={BadgeForm}/>
            <Route path={c.APP_ROUTES.BADGE_CREATE} exact component={BadgeForm}/>

            <Route path={c.APP_ROUTES.SOLUTION_BADGE} exact component={SolutionBadgeDataTable}/>
            <Route path={c.APP_ROUTES.SOLUTION_BADGE_UPDATE} exact component={SolutionBadgeForm}/>
            <Route path={c.APP_ROUTES.SOLUTION_BADGE_CREATE} exact component={SolutionBadgeForm}/>

            <Route path={c.APP_ROUTES.SOLUTION_INPUT_TAG} exact component={SolutionInputTagDataTable}/>
            <Route path={c.APP_ROUTES.SOLUTION_INPUT_TAG_UPDATE} exact component={SolutionInputTagForm}/>
            <Route path={c.APP_ROUTES.SOLUTION_INPUT_TAG_CREATE} exact component={SolutionInputTagForm}/>

            <Route path={c.APP_ROUTES.SOLUTION} exact component={SolutionDataTable}/>
            <Route path={c.APP_ROUTES.SOLUTION_UPDATE} exact component={SolutionForm}/>
            <Route path={c.APP_ROUTES.SOLUTION_CREATE} exact component={SolutionForm}/>

            <Route path={c.APP_ROUTES.ENTRY_TAG} exact component={EntryTagDataTable}/>
            <Route path={c.APP_ROUTES.ENTRY_TAG_UPDATE} exact component={EntryTagForm}/>
            <Route path={c.APP_ROUTES.ENTRY_TAG_CREATE} exact component={EntryTagForm}/>

            <Route path={c.APP_ROUTES.TAG} exact component={TagDataTable}/>
            <Route path={c.APP_ROUTES.TAG_UPDATE} exact component={TagForm}/>
            <Route path={c.APP_ROUTES.TAG_CREATE} exact component={TagForm}/>

            <Route path={c.APP_ROUTES.MATCH} exact component={MatchDataTable}/>
            <Route path={c.APP_ROUTES.MATCH_UPDATE} exact component={MatchForm}/>
            <Route path={c.APP_ROUTES.MATCH_CREATE} exact component={MatchForm}/>

            <Route path={c.APP_ROUTES.MATCH_IMPACT_OVERVIEW} exact component={ImpactOverview}/>
            <Route path={c.APP_ROUTES.MATCH_IMPACT_OVERVIEW_NO_SOLUTION} exact component={ImpactOverview}/>
            <Route path={c.APP_ROUTES.VALUATION_DASHBOARD} exact component={ValuationDashboard}/>
            <Route path={c.APP_ROUTES.VALUATION_DASHBOARD_SHORTCODE} exact component={ValuationDashboard}/>

            <Route path={c.APP_ROUTES.SOLUTION_OUTPUT_TAG} exact component={SolutionOutputTagDataTable}/>
            <Route path={c.APP_ROUTES.SOLUTION_OUTPUT_TAG_UPDATE} exact component={SolutionOutputTagForm}/>
            <Route path={c.APP_ROUTES.SOLUTION_OUTPUT_TAG_CREATE} exact component={SolutionOutputTagForm}/>

            <Route path={c.APP_ROUTES.SOLUTION_FUNCTION} exact component={SolutionFunctionDataTable}/>
            <Route path={c.APP_ROUTES.SOLUTION_FUNCTION_UPDATE} exact component={SolutionFunctionForm}/>
            <Route path={c.APP_ROUTES.SOLUTION_FUNCTION_CREATE} exact component={SolutionFunctionForm}/>

            <Route path={c.APP_ROUTES.MATERIALSFUNCTION} exact component={MaterialsFunctionDataTable}/>
            <Route path={c.APP_ROUTES.MATERIALSFUNCTION_UPDATE} exact component={MaterialsFunctionForm}/>
            <Route path={c.APP_ROUTES.MATERIALSFUNCTION_CREATE} exact component={MaterialsFunctionForm}/>

            <Route path={c.APP_ROUTES.ADMIN_VERIFICATION} exact component={Verification}/>

            <Route path={c.APP_ROUTES.SHADOW_ENTRY} exact component={ShadowEntryDataTable}/>
            <Route path={c.APP_ROUTES.SHADOW_ENTRY_UPDATE} exact component={ShadowEntryForm}/>
            <Route path={c.APP_ROUTES.SHADOW_ENTRY_CREATE} exact component={ShadowEntryForm}/>

            <Route path={c.APP_ROUTES.MARKETPLACE} exact component={MarketplaceDataTable}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_UPDATE} exact component={MarketplaceForm}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_CREATE} exact component={MarketplaceForm}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_CLIENT_ADMIN_CREATE} exact component={MarketplaceClientAdminForm}/>


            <Route path={c.APP_ROUTES.VIEW_MARKETPLACES} exact component={MarketplacesViewRenderer}/>
            <Route path={c.APP_ROUTES.VIEW_MARKETPLACE} exact component={Marketplace}/>
            <Route path={c.APP_ROUTES.VIEW_MARKETPLACES_SHORTCODE} exact component={MarketplacesViewRenderer}/>
            <Route path={c.APP_ROUTES.VIEW_MARKETPLACE_SHORTCODE} exact component={Marketplace}/>

            <Route path={c.APP_ROUTES.MARKETPLACE_ENTRY} exact component={MarketplaceEntryDataTable}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_ENTRY_UPDATE} exact component={MarketplaceEntryForm}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_ENTRY_CREATE} exact component={MarketplaceEntryForm}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_CREATE_STEP_ONE} exact component={MarketplaceStepOne}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_CREATE_STEP_TWO} exact component={MarketplaceStepTwo}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_EDIT_STEP_TWO} exact component={MarketplaceStepTwo}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_CREATE_STEP_THREE} exact component={MarketplaceStepThree}/>
            <Route path={c.APP_ROUTES.MARKETPLACE_EDIT_STEP_THREE} exact component={MarketplaceStepThree}/>


            <Route path={c.APP_ROUTES.VIEW_AUCTIONS} exact component={AuctionHub}/>
            <Route path={c.APP_ROUTES.VIEW_AUCTION} exact component={Auction}/>
            <Route path={c.APP_ROUTES.VIEW_AUCTION_ENTRY} exact component={AuctionEntryRenderer}/>

            <Route path={c.APP_ROUTES.AUCTION} exact component={AuctionDataTable}/>
            <Route path={c.APP_ROUTES.AUCTION_UPDATE} exact component={AuctionForm}/>
            <Route path={c.APP_ROUTES.AUCTION_CREATE} exact component={AuctionForm}/>

            <Route path={c.APP_ROUTES.AUCTION_CREATE_STEP_ONE} exact component={AuctionStepOne}/>
            <Route path={c.APP_ROUTES.AUCTION_CREATE_STEP_TWO} exact component={AuctionStepTwo}/>
            <Route path={c.APP_ROUTES.AUCTION_CREATE_STEP_THREE} exact component={AuctionStepThree}/>
            <Route path={c.APP_ROUTES.AUCTION_CREATE_STEP_FOUR} exact component={AuctionStepFour}/>

            <Route path={c.APP_ROUTES.AUCTION_BID} exact component={AuctionBidDataTable}/>
            <Route path={c.APP_ROUTES.AUCTION_BID_UPDATE} exact component={AuctionBidForm}/>
            <Route path={c.APP_ROUTES.AUCTION_BID_CREATE} exact component={AuctionBidForm}/>

            <Route path={c.APP_ROUTES.LEAD} exact component={LeadDataTable}/>
            <Route path={c.APP_ROUTES.LEAD_UPDATE} exact component={LeadForm}/>
            <Route path={c.APP_ROUTES.LEAD_CREATE} exact component={LeadForm}/>
            <Route path={c.APP_ROUTES.REGISTER_LEAD} exact component={LeadRegistrationForm}/>

            <Route path={c.APP_ROUTES.CONTACT} exact component={ContactDataTable}/>
            <Route path={c.APP_ROUTES.CONTACT_UPDATE} exact component={ContactForm}/>
            <Route path={c.APP_ROUTES.CONTACT_CREATE} exact component={ContactForm}/>

            <Route path={c.APP_ROUTES.COMPOSITION} exact component={CompositionDataTable}/>
            <Route path={c.APP_ROUTES.COMPOSITION_UPDATE} exact component={CompositionForm}/>
            <Route path={c.APP_ROUTES.COMPOSITION_CREATE} exact component={CompositionForm}/>

            <Route path={c.APP_ROUTES.ENTRY_COMPOSITION} exact component={EntryCompositionDataTable}/>
            <Route path={c.APP_ROUTES.ENTRY_COMPOSITION_UPDATE} exact component={EntryCompositionForm}/>
            <Route path={c.APP_ROUTES.ENTRY_COMPOSITION_CREATE} exact component={EntryCompositionForm}/>

            <Route path={c.APP_ROUTES.OFFER} exact component={OfferDataTable}/>
            <Route path={c.APP_ROUTES.OFFER_UPDATE} exact component={OfferForm}/>
            <Route path={c.APP_ROUTES.OFFER_CREATE} exact component={OfferForm}/>

            <Route path={c.APP_ROUTES.OFFER_ITEM} exact component={OfferItemDataTable}/>
            <Route path={c.APP_ROUTES.OFFER_ITEM_UPDATE} exact component={OfferItemForm}/>
            <Route path={c.APP_ROUTES.OFFER_ITEM_CREATE} exact component={OfferItemForm}/>

            <Route path={c.APP_ROUTES.OFFER_PROJECT_MANAGER} exact component={OfferProjectManagerDataTable}/>
            <Route path={c.APP_ROUTES.OFFER_PROJECT_MANAGER_UPDATE} exact component={OfferProjectManagerForm}/>
            <Route path={c.APP_ROUTES.OFFER_PROJECT_MANAGER_CREATE} exact component={OfferProjectManagerForm}/>

            <Route path={c.APP_ROUTES.OFFER_ITEM_STATUS_CODE} exact component={OfferItemStatusCodeDataTable}/>
            <Route path={c.APP_ROUTES.OFFER_ITEM_STATUS_CODE_UPDATE} exact component={OfferItemStatusCodeForm}/>
            <Route path={c.APP_ROUTES.OFFER_ITEM_STATUS_CODE_CREATE} exact component={OfferItemStatusCodeForm}/>

            <Route path={c.APP_ROUTES.AUCTION_ENTRY} exact component={AuctionEntryDataTable}/>
            <Route path={c.APP_ROUTES.AUCTION_ENTRY_UPDATE} exact component={AuctionEntryForm}/>
            <Route path={c.APP_ROUTES.AUCTION_ENTRY_CREATE} exact component={AuctionEntryForm}/>

            <Route path={c.APP_ROUTES.OFFER_STATUS_CODE} exact component={OfferStatusCodeDataTable}/>
            <Route path={c.APP_ROUTES.OFFER_STATUS_CODE_UPDATE} exact component={OfferStatusCodeForm}/>
            <Route path={c.APP_ROUTES.OFFER_STATUS_CODE_CREATE} exact component={OfferStatusCodeForm}/>

            <Route path={c.APP_ROUTES.SEARCH_ENGINE} exact component={SearchEngine}/>
            <Route path={c.APP_ROUTES.SEARCH_ENGINE_SHORTCODE} exact component={SearchEngine}/>
            <Route path={c.APP_ROUTES.POPULAR_SEARCH_CREATE} exact component={PopularSearch}/>
            <Route path={c.APP_ROUTES.POPULAR_SEARCH} exact component={PopularSearchDataTable}/>

            <Route path={c.APP_ROUTES.DEAL_HUB} exact component={DealHub}/>
            <Route path={c.APP_ROUTES.ADD_NEW_OFFER} exact component={ABBOfferForm}/>
            <Route path={c.APP_ROUTES.REVIEW_OFFER} exact component={ABBOfferForm}/>
            <Route path={c.APP_ROUTES.CREATE_ESTIMATION} exact component={ABBEstimationForm}/>
            <Route path={c.APP_ROUTES.REVIEW_ESTIMATION} exact component={ABBReviewEstimation}/>
            <Route path={c.APP_ROUTES.SHIPMENT_JOURNEY} exact component={Shipment}/>
            <Route path={c.APP_ROUTES.GREEN_REPORT} exact component={GreenReport}/>

            <Route path={c.APP_ROUTES.SUBSCRIPTION} exact component={Subscription}/>
            <Route path={c.APP_ROUTES.RETURN} exact component={PaymentStatusPage}/>
            <Route path={c.APP_ROUTES.PLAN} exact component={PlanDataTable}/>
            <Route path={c.APP_ROUTES.PLAN_CREATE} exact component={PlanForm}/>
            <Route path={c.APP_ROUTES.PLAN_UPDATE} exact component={PlanForm}/>
            <Route path={c.APP_ROUTES.SUBSCRIPTION_ADMIN} exact component={SubscriptionDataTable}/>
            <Route path={c.APP_ROUTES.SUBSCRIPTION_ADMIN_CREATE} exact component={SubscriptionForm}/>
            <Route path={c.APP_ROUTES.SUBSCRIPTION_ADMIN_UPDATE} exact component={SubscriptionForm}/>
            <Route path={c.APP_ROUTES.TRANSACTION} exact component={TransactionDataTable}/>
            <Route path={c.APP_ROUTES.TRANSACTION_CREATE} exact component={TransactionForm}/>
            <Route path={c.APP_ROUTES.TRANSACTION_UPDATE} exact component={TransactionForm}/>

            <Route path={c.APP_ROUTES.OPP_NOTIFICATION} exact component={OPPNotificationDataTable}/>

            <Route path={c.APP_ROUTES.STATUS_CODE} exact component={StatusCodeDataTable}/>
            <Route path={c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE} exact component={EntryRenderer}/>
            <Route path={c.APP_ROUTES.VIEW_RENDERER_SHORTCODE} exact component={ViewRenderer}/>
            <Route path={c.APP_ROUTES.USER_LOGIN_SHORTCODE} exact component={UserLogin}/>
            <Route path={c.APP_ROUTES.USER_REGISTER_SHORTCODE} exact component={UserRegister}/>
            <Route path={c.APP_ROUTES.DASHBOARD_SHORTCODE} exact component={Dashboard}/>
            <Route path={c.APP_ROUTES.DASHBOARD} exact component={Dashboard}/>
            <Route path={c.APP_ROUTES.LANDING_PAGE} exact component={LandingPage}/>
            <Route path={c.APP_ROUTES.SEARCH_RESULT} exact component={SearchResults}/>
            <Route path={c.APP_ROUTES.STATIC_PAGE_VIEW} exact component={StaticPageView}/>
            <Route path={c.APP_ROUTES.ERROR_404} exact component={Error404}/>

        </Switch>
      </ContextProvider>
  );
};

export default Router;
