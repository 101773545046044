import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getEntryTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_TAG}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getEntryTagsByEntryId(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_TAG}/entry/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getEntryTags(dataTableParams?: DataTableParamsModel) {
  const baseUrl = `${c.API_ENDPOINTS.ENTRY_TAG}`;
  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postEntryTag(groupId: string, clientId: string, entryId: string, tagId: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ENTRY_TAG,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        entryId,
        tagId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putEntryTag(
  id: string,
  groupId: string,
  clientId: string,
  entryId: string,
  tagId: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_TAG}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        entryId,
        tagId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveEntryTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_TAG}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveEntryTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ENTRY_TAG}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
