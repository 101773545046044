import React, { useEffect, useState } from 'react';
import DropDown from '../../components/DropDown';
import { Item } from '../DropDown/DropDown';
import Pill from '../../components/Pill';

interface MultiSelectWithPillsProps {
  id: string;
  placeholder: string;
  value: string;
  label: string;
  items: Item[];
  selectedItems: { id: string; label: string }[];
  onSelect: (selectedItem: Item) => void;
  onUnselect: (id: string, label: string) => void;
  disabled?: boolean;
  v?: any;
  err?: any;
}

const MultiSelectWithPills: React.FC<MultiSelectWithPillsProps> = ({
  id,
  placeholder,
  label,
  items,
  selectedItems,
  onSelect,
  onUnselect,
  disabled = false,
  v,
  err,
  value,
}) => {
  const [touched, setTouched] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleSelect = (item: Item) => {
    if (item && item.label) {
      setTouched(true);
      onSelect(item);
      if (v) {
        v(id, selectedItems.length + 1 > 0 ? selectedItems : null);
      }
    }
  };

  const handleUnselect = (selectedId: string, selectedLabel: string) => {
    setTouched(true);
    onUnselect(selectedId, selectedLabel);
    if (v) {
      v(id, selectedItems.length - 1 > 0 ? selectedItems : null);
    }
  };

  useEffect(() => {
    // Initial validation if there's a pre-selected value
    if (!touched && selectedItems.length > 0 && !updated) {
      if (v) {
        v(id, selectedItems);
        setUpdated(true);
      }
    }
  }, [selectedItems, id, v, touched, updated]);

  return (
        <div>
            <DropDown
                v={v}
                err={err}
                id={id}
                placeholder={placeholder}
                label={label}
                items={items}
                disabled={disabled}
                value={value}
                onSelect={handleSelect}
                type="default"
            />
            {selectedItems.length > 0 && (
                <div className="category__container__pill-container">
                    {selectedItems.map(e => (
                        <Pill
                            key={e.id}
                            text={e.label}
                            type="white"
                            cancellable
                            onClick={() => handleUnselect(e.id, e.label)}
                        />
                    ))}
                </div>
            )}
        </div>
  );
};

export default MultiSelectWithPills;
