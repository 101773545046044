import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getFields, isFetchingFields } from '../../../selectors/Field';
import { activateField, fetchFields, storeField } from '../../../actions/Field';
import { Field } from '../../../types/Field';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const FieldDataTable = () => {

  const fields = useSelector(getFields);
  const isLoading = useSelector(isFetchingFields);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [field, setActiveField] = useState<Field | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.FIELD_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.FIELD_UPDATE, row.id));
  };

  const onArchive = (row: Field) => {
    setActiveField(row);
    setToggle(true);
  };

  const onUnArchive = (row: Field) => {
    setActiveField(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (field?.id) {
      if (unArchiveToggle){
        await activateField(field?.id);
      } else {
        await storeField(field?.id);
      }
      setRefetch(!refetch);
    }
    setActiveField(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'categoryTitle', label: 'Category', mobileFriendly: true },
      { key: 'fieldTypeTitle', label: 'Field Type', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (
      <View title="Fields" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchFields} paging={fields?.paging || []}
                 isLoading={isLoading}  data={fields?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
    </View>
  );
};

export default FieldDataTable;

