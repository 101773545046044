import axios from 'axios';

import c, { LeadStatus } from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getLead(id: string, dataTableParams?: DataTableParamsModel) {
  const baseUrl = `${c.API_ENDPOINTS.LEAD}`;
  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  return (
    axios({
      url: `${url}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getLeads(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.LEAD,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postLead(
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  companyName: string,
  companyCommercialNumber: string,
  companyPhone: string,
  companyEmail: string,
  companyWebsite: string,
  roleInCompany: string,
  city: string,
  country: string,
  termsAccepted: boolean,
  verification: LeadStatus | undefined,
  transaction: LeadStatus | undefined,
  agreement: LeadStatus | undefined,
  onboarded: LeadStatus | undefined,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.LEAD,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        companyCommercialNumber,
        companyPhone,
        companyEmail,
        companyWebsite,
        roleInCompany,
        city,
        country,
        termsAccepted,
        verification,
        transaction,
        agreement,
        onboarded,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putLead(
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  companyName: string,
  companyCommercialNumber: string,
  companyPhone: string,
  companyEmail: string,
  companyWebsite: string,
  roleInCompany: string,
  city: string,
  country: string,
  termsAccepted: boolean,
  verification: LeadStatus,
  transaction: LeadStatus,
  agreement: LeadStatus,
  onboarded: LeadStatus,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LEAD}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        companyCommercialNumber,
        companyPhone,
        companyEmail,
        companyWebsite,
        roleInCompany,
        city,
        country,
        termsAccepted,
        verification,
        transaction,
        agreement,
        onboarded,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function archiveLead(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LEAD}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveLead(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LEAD}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postConvertLead(leadId: string, groupId: string, clientId: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LEAD}/${leadId}/convert`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

