import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getFieldTypeOptions, isFetchingFieldTypeOptions } from '../../../selectors/FieldTypeOption';
import { activateFieldTypeOption, fetchFieldTypeOptions, storeFieldTypeOption } from '../../../actions/FieldTypeOption';
import { FieldTypeOption } from '../../../types/FieldTypeOption';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const FieldTypeOptionDataTable = () => {

  const fieldTypeOptions = useSelector(getFieldTypeOptions);
  const isLoading = useSelector(isFetchingFieldTypeOptions);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [fieldTypeOption, setActiveFieldTypeOption] = useState<FieldTypeOption | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.FIELD_TYPE_OPTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.FIELD_TYPE_OPTION_UPDATE, row.id));
  };

  const onArchive = (row: FieldTypeOption) => {
    setActiveFieldTypeOption(row);
    setToggle(true);
  };

  const onUnArchive = (row: FieldTypeOption) => {
    setActiveFieldTypeOption(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (fieldTypeOption?.id) {
      if (unArchiveToggle){
        await activateFieldTypeOption(fieldTypeOption?.id);
      } else {
        await storeFieldTypeOption(fieldTypeOption?.id);
      }
      setRefetch(!refetch);
    }
    setActiveFieldTypeOption(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'fieldTypeTitle', label: 'Field Type', mobileFriendly: true },
      { key: 'key', label: 'Key', mobileFriendly: true },
      { key: 'defaultValue', label: 'Value', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (
      <View title="Field Type Options" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchFieldTypeOptions} paging={fieldTypeOptions?.paging || []}
                 isLoading={isLoading} data={fieldTypeOptions?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
    </View>
  );
};

export default FieldTypeOptionDataTable;

