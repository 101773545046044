import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTracking = (userId:string | undefined) => {
  const location = useLocation();
  console.log('Starting gtag config');

  useEffect(() => {
    if (window.gtag && userId) {
      window.gtag('config', 'G-B6B726W0TZ', {
        page_path: location.pathname,
        user_id: userId || undefined,
      });
    }
  }, [location, userId]);
};

export default usePageTracking;
