import { ApiDataModel, FormError } from './Common';

export const FETCH_SOLUTION_BADGES_REQUEST = 'FETCH_SOLUTION_BADGES_REQUEST';
export const FETCH_SOLUTION_BADGES_RESPONSE = 'FETCH_SOLUTION_BADGES_RESPONSE';
export const FETCH_SOLUTION_BADGES_FAILURE = 'FETCH_SOLUTION_BADGES_FAILURE';

export const FETCH_SOLUTION_BADGE_REQUEST = 'FETCH_SOLUTION_BADGE_REQUEST';
export const FETCH_SOLUTION_BADGE_RESPONSE = 'FETCH_SOLUTION_BADGE_RESPONSE';
export const FETCH_SOLUTION_BADGE_FAILURE = 'FETCH_SOLUTION_BADGE_FAILURE';

export const POST_SOLUTION_BADGE_REQUEST = 'POST_SOLUTION_BADGE_REQUEST';
export const POST_SOLUTION_BADGE_RESPONSE = 'POST_SOLUTION_BADGE_RESPONSE';
export const POST_SOLUTION_BADGE_FAILURE = 'POST_SOLUTION_BADGE_FAILURE';

export interface SolutionBadge {
  id: string;
  badgeId: string;
  solutionId: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface SolutionBadgeDataModel extends Omit<ApiDataModel, 'data'> {
  data: SolutionBadge[];
}

export interface SolutionBadgeState {
  solutionBadges: SolutionBadgeDataModel | null,
  activeSolutionBadge: SolutionBadge | null,
  isFetchingSolutionBadges: boolean;
  fetchingSolutionBadgesFailed: boolean;
  postingSolutionBadgeFailed?: boolean;
  isPostingSolutionBadge: boolean;
  postingSolutionBadgeValidationErrors: FormError[] | null;
  postingSolutionBadgeError: string | null;
}

interface FetchSolutionBadgesRequestAction {
  type: typeof FETCH_SOLUTION_BADGES_REQUEST;
  payload: null;
}

interface FetchSolutionBadgesResponseAction {
  type: typeof FETCH_SOLUTION_BADGES_RESPONSE;
  payload: SolutionBadgeDataModel;
}

interface FetchSolutionBadgesFailureAction {
  type: typeof FETCH_SOLUTION_BADGES_FAILURE;
  payload: null;
}

interface FetchSolutionBadgeRequestAction {
  type: typeof FETCH_SOLUTION_BADGE_REQUEST;
  payload: null;
}

interface FetchSolutionBadgeResponseAction {
  type: typeof FETCH_SOLUTION_BADGE_RESPONSE;
  payload: SolutionBadge;
}

interface FetchSolutionBadgeFailureAction {
  type: typeof FETCH_SOLUTION_BADGE_FAILURE;
  payload: null;
}

interface PostSolutionBadgeRequestAction {
  type: typeof POST_SOLUTION_BADGE_REQUEST;
  payload: null;
}

interface PostSolutionBadgeResponseAction {
  type: typeof POST_SOLUTION_BADGE_RESPONSE;
  payload: null;
}

interface PostSolutionBadgeFailureAction {
  type: typeof POST_SOLUTION_BADGE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type SolutionBadgeActionTypes =
    | FetchSolutionBadgesRequestAction
    | FetchSolutionBadgesResponseAction
    | FetchSolutionBadgesFailureAction
    | PostSolutionBadgeRequestAction
    | PostSolutionBadgeResponseAction
    | PostSolutionBadgeFailureAction
    | FetchSolutionBadgeRequestAction
    | FetchSolutionBadgeResponseAction
    | FetchSolutionBadgeFailureAction;
