import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createSolutionBadge, fetchActiveSolutionBadge, updateSolutionBadge } from '../../../actions/SolutionBadge';
import { getBadges } from '../../../selectors/Badge';
import CheckBox from '../../../components/CheckBox';
import { fetchBadges } from '../../../actions/Badge';
import {
  getActiveSolutionBadge,
  isPostingSolutionBadge,
  postingSolutionBadgeError,
  postingSolutionBadgeFailed,
  postingSolutionBadgeValidationErrors,
  isFetchingSolutionBadges,
} from '../../../selectors/SolutionBadge';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchSolutions } from '../../../actions/Solution';
import { getSolutions } from '../../../selectors/Solution';


const SolutionBadgeForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeSolutionBadge = useSelector(getActiveSolutionBadge);
  const badges = useSelector(getBadges);
  const solutions = useSelector(getSolutions);
  const isPosting = useSelector(isPostingSolutionBadge);
  const isFetching = useSelector(isFetchingSolutionBadges);
  const postingFailed = useSelector(postingSolutionBadgeFailed);
  const postingError = useSelector(postingSolutionBadgeError);
  const postingValidationErrors = useSelector(postingSolutionBadgeValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.SOLUTION_BADGE_UPDATE);

  const formConfig = [
    { field: 'badgeId', validators: [isRequired] },
  ];

  const { v, triggerValidation, isFormValid, err } = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [badgeId, setBadgeId] = useState('');
  const [solutionId, setSolutionId] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchBadges());
    dispatch(fetchSolutions());
    if (editMode) {
      dispatch(fetchActiveSolutionBadge(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeSolutionBadge && editMode) {
      setBadgeId(activeSolutionBadge?.badgeId);
      setSolutionId(activeSolutionBadge?.solutionId);
      // @ts-ignore
      setActive(activeSolutionBadge?.active === 1 || activeSolutionBadge?.active === true);
    }
  }, [activeSolutionBadge]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.SOLUTION_BADGE);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createSolutionBadge(badgeId, solutionId, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateSolutionBadge(activeId, badgeId, solutionId, active));
    }
  };

  const itemsBadges = badges?.data.map(r => ({ label: r.value, value: r.id })) || [];
  const itemsSolutions = solutions?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onBadgeSelect = (obj: any) => {
    setBadgeId(obj.value);
  };

  const onSolutionSelect = (obj: any) => {
    setSolutionId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} SolutionBadge</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="badgeId"
                          placeholder="Please select a Badge"
                          required label="Badge"
                          items={itemsBadges}
                          value={badgeId}
                          disabled={isPosting || isFetching}
                          onSelect={onBadgeSelect}
                          type="default"
                />
              <DropDown v={v} err={err}
                        id="solutionId"
                        placeholder="Please select a Solution"
                        required label="Solution"
                        items={itemsSolutions}
                        value={solutionId}
                        disabled={isPosting || isFetching}
                        onSelect={onSolutionSelect}
                        type="default"
              />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default SolutionBadgeForm;
