import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getForm(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getForms(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.FORM,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postForm(groupId: string, clientId: string, title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.FORM,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putForm(
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string | null,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveForm(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveForm(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FORM}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

