import React, { useState } from 'react';
// @ts-ignore
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getViewsData, isFetchingViews } from '../../../selectors/View';
import { activateView, fetchViewsData, storeView } from '../../../actions/View';
import { View as ViewModel } from '../../../types/View';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const ViewDataTable = () => {

  const views = useSelector(getViewsData);
  const isLoading = useSelector(isFetchingViews);
  const [toggle, setToggle] = useState(false);
  // @ts-ignore
  const [view, setActiveView] = useState<View | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.VIEW_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.VIEW_UPDATE, row.id));
  };

  // @ts-ignore
  const onArchive = (row: ViewModel) => {
    setActiveView(row);
    setToggle(true);
  };

  const onUnArchive = (row: ViewModel) => {
    setActiveView(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (view?.id) {
      if (unArchiveToggle){
        await activateView(view?.id);
      } else {
        await storeView(view?.id);
      }
      setRefetch(!refetch);
    }
    setActiveView(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'formTitle', label: 'Form', mobileFriendly: true },
      { key: 'order', label: 'Order', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      //commented out because dynamic html pages are too long, can style later
      // { key: 'contents', label: 'Contents', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
      <View title="Views" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchViewsData} paging={views?.paging || []} isLoading={isLoading}  data={views?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive View' : 'Archive View'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this View?' : 'Are you sure you want to archive this View?'}
        </Modal>
    </View>
  );
};

export default ViewDataTable;

