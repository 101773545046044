import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TAG}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getTags(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.TAG,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postTag(value: string, type: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.TAG,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        value,
        type,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postTags(value: string[], type: string[], active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.TAGS,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        value,
        type,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putTag(
  id: string,
  value: string,
  type: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TAG}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        value,
        type,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TAG}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveTag(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.TAG}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

