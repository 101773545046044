import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getSolutions, isFetchingSolutions } from '../../../selectors/Solution';
import { activateSolution, fetchSolutions, storeSolution } from '../../../actions/Solution';
import { Solution } from '../../../types/Solution';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const SolutionDataTable = () => {

  const solutions = useSelector(getSolutions);
  const isLoading = useSelector(isFetchingSolutions);
  const [toggle, setToggle] = useState(false);
  const [solution, setActiveSolution] = useState<Solution | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.SOLUTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.SOLUTION_UPDATE, row.id));
  };

  const onArchive = (row: Solution) => {
    setActiveSolution(row);
    setToggle(true);
  };

  const onUnArchive = (row: Solution) => {
    setActiveSolution(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (solution?.id) {
      if (unArchiveToggle){
        await activateSolution(solution?.id);
      } else {
        await storeSolution(solution?.id);
      }
      setRefetch(!refetch);
    }
    setActiveSolution(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'location', label: 'Location', mobileFriendly: true },
      // { key: 'contactNumber', label: 'Contact Number', mobileFriendly: true },
      // { key: 'contactEmail', label: 'Contact Email', mobileFriendly: true },
      // { key: 'contactPerson', label: 'Contact Person', mobileFriendly: true },
      // { key: 'active', label: 'Active', mobileFriendly: false },
      // { key: 'created', label: 'Created At', mobileFriendly: false },
      // { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
      <View title="Solutions" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchSolutions} paging={solutions?.paging || []} isLoading={isLoading}
                   data={solutions?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Solution' : 'Archive Solution'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this Solution?' : 'Are you sure you want to archive this Solution?'}
        </Modal>
      </View>
  );
};

export default SolutionDataTable;

