import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getTransactions, isFetchingTransactions } from '../../../selectors/Transaction';
import { activateTransaction, fetchTransactions, storeTransaction } from '../../../actions/Transaction';
import { Transaction } from '../../../types/Transaction';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const TransactionDataTable = () => {

  const transactions = useSelector(getTransactions);
  const isLoading = useSelector(isFetchingTransactions);
  const [toggle, setToggle] = useState(false);
  const [transaction, setActiveTransaction] = useState<Transaction | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.TRANSACTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.TRANSACTION_UPDATE, row.id));
  };

  const onArchive = (row: Transaction) => {
    setActiveTransaction(row);
    setToggle(true);
  };

  const onUnArchive = (row: Transaction) => {
    setActiveTransaction(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (transaction?.id) {
      if (unArchiveToggle){
        await activateTransaction(transaction?.id);
      } else {
        await storeTransaction(transaction?.id);
      }
      setRefetch(!refetch);
    }
    setActiveTransaction(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };
  
  const config = {
    columns: [
      { key: 'oppTransactionId', label: 'OPP Transaction ID', mobileFriendly: true },
      { key: 'status', label: 'Status', mobileFriendly: true },
      { key: 'merchant_uid', label: 'Merchant ID', mobileFriendly: false },
      { key: 'profile_uid', label: 'Profile ID', mobileFriendly: true },
      { key: 'amount', label: 'Amount', mobileFriendly: true },
      { key: 'currency', label: 'Currency', mobileFriendly: false },
      { key: 'payment_method', label: 'Payment Method', mobileFriendly: true },
      { key: 'payment_flow', label: 'Payment Flow', mobileFriendly: true },
      { key: 'redirect_url', label: 'Redirect URL', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Transactions" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchTransactions} data={transactions?.data || []}
                       isLoading={isLoading} paging={transactions?.paging || []} config={config} dependencies={refetch} />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Transaction' : 'Archive Transaction'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this Transaction?' : 'Are you sure you want to archive this Transaction?'}
          </Modal>
        </View>
  );
};

export default TransactionDataTable;
