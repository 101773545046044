import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SOLUTIONS_REQUEST,
  FETCH_SOLUTIONS_RESPONSE,
  FETCH_SOLUTIONS_FAILURE,
  FETCH_SOLUTION_REQUEST,
  FETCH_SOLUTION_RESPONSE,
  FETCH_SOLUTION_FAILURE,
  POST_SOLUTION_FAILURE,
  POST_SOLUTION_REQUEST,
  POST_SOLUTION_RESPONSE,
  SolutionActionTypes, SolutionDataModel, Solution,
} from '../types/Solution';

import { RootState } from '../store/configureStore';

import { archiveSolution, unarchiveSolution, getSolution, getSolutions, postSolution, putSolution } from '../api/Solution';
import { DataTableParamsModel } from '../types/Common';

export function fetchSolutionsRequest(): SolutionActionTypes {
  return {
    type: FETCH_SOLUTIONS_REQUEST,
    payload: null,
  };
}

export function fetchSolutionsResponse(
  solutions: SolutionDataModel,
): SolutionActionTypes {
  return {
    type: FETCH_SOLUTIONS_RESPONSE,
    payload: solutions,
  };
}

export function fetchSolutionsFailure(): SolutionActionTypes {
  return {
    type: FETCH_SOLUTIONS_FAILURE,
    payload: null,
  };
}

export function fetchSolutionRequest(): SolutionActionTypes {
  return {
    type: FETCH_SOLUTION_REQUEST,
    payload: null,
  };
}

export function fetchSolutionResponse(
  solution: Solution,
): SolutionActionTypes {
  return {
    type: FETCH_SOLUTION_RESPONSE,
    payload: solution,
  };
}

export function fetchSolutionFailure(): SolutionActionTypes {
  return {
    type: FETCH_SOLUTION_FAILURE,
    payload: null,
  };
}


export function postSolutionRequest(): SolutionActionTypes {
  return {
    type: POST_SOLUTION_REQUEST,
    payload: null,
  };
}

export function postSolutionResponse(): SolutionActionTypes {
  return {
    type: POST_SOLUTION_RESPONSE,
    payload: null,
  };
}

export function postSolutionFailure(error: string, validationErrors: any): SolutionActionTypes {
  return {
    type: POST_SOLUTION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveSolution = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionRequest());
    const asyncResp: any = await getSolution(id);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionResponse(asyncResp.data));
    } else {
      await dispatch(fetchSolutionFailure());
    }
  };

export const fetchSolutions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionsRequest());
    const asyncResp: any = await getSolutions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionsResponse(asyncResp));
    } else {
      await dispatch(fetchSolutionsFailure());
    }
  };

export const storeSolution = async (id: string) => {
  try {
    await archiveSolution(id);
  } catch (error) {
    throw error;
  }
};

export const activateSolution = async (id: string) => {
  try {
    await unarchiveSolution(id);
  } catch (error) {
    throw error;
  }
};

export const createSolution = (
  title: string, description: string, location: string, contactNumber: string, contactEmail: string, contactPerson: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionRequest());
    const asyncResp: any = await postSolution(title, description, location, contactNumber, contactEmail, contactPerson, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionResponse());
    } else {
      await dispatch(postSolutionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateSolution = (
  id: string,
  title: string,
  description: string,
  location: string,
  contactNumber: string,
  contactEmail: string,
  contactPerson: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionRequest());
    const asyncResp: any = await putSolution(id, title, description, location, contactNumber, contactEmail, contactPerson, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionResponse());
    } else {
      await dispatch(postSolutionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


