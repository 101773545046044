import React, { useEffect, useState } from 'react';
import './ImpactOverview.scss';
import {  useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import { getMatches, isFetchingMatches, isPostingMatch } from '../../selectors/Match';
import { formatUrl } from '../../helpers/functions';
import Chart from './Chart';
import Pointer from './Pointer';
import Card from '../../components/Card/Card';
import { getEntryTags } from '../../selectors/EntryTag';
import Pill from '../../components/Pill/Pill';
import DataTable from '../../components/DataTable';
import { fetchMatchesByEntry } from '../../actions/Match';
import useShortcodes from '../../hooks/ShortcodeHook';

const ImpactOverview = () => {

  const {
    isUsingShortcodes,
    groupShortcode,
    clientShortcode,
  } = useShortcodes(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE, c.APP_ROUTES.ENTRY_RENDERER);

  const history = useHistory();
  const matchUpdate = !isUsingShortcodes ? useRouteMatch(c.APP_ROUTES.ENTRY_RENDERER) : useRouteMatch(c.APP_ROUTES.ENTRY_RENDERER_SHORTCODE);
  // @ts-ignore
  const entryId = matchUpdate?.params?.id;
  const entryMatches = useSelector(getMatches);
  const isPostingMatches = useSelector(isPostingMatch);
  const isLoadingMatches = (useSelector(isFetchingMatches) || isPostingMatches || ( (entryMatches && entryMatches?.data.length > 0) ? !(entryMatches?.data.some(entryMatch => entryMatch.entryId === entryId)) : false));
  const tags = useSelector(getEntryTags);
  const [activeSolution, setActiveSolution] = useState(entryMatches && entryMatches.data[0] ? entryMatches?.data[0].id : '');
  const [newMatches, setNewMatches] = useState<Match[]>([]);
  const match = entryMatches?.data.find(em => em.id === activeSolution);

  interface Match {
    id?: string;
    entryTitle?: string;
    entryDescription?: string;
    solutionTitle?: string;
    solutionDescription?: string;
  }

  const toValuation = (id: string) => {
    if (isUsingShortcodes && groupShortcode && clientShortcode){
      history.push(formatUrl(c.APP_ROUTES.VALUATION_DASHBOARD_SHORTCODE, groupShortcode, clientShortcode, id));
    } else {
      history.push(formatUrl(c.APP_ROUTES.VALUATION_DASHBOARD, id));
    }
  };

  const onSolutionSelect = (row: any) => {
    setActiveSolution(row);
  };

  useEffect(() => {
    if (entryMatches && entryMatches.data) {
      const innerMatches: any[] = [];
      entryMatches.data.map((entryMatch) => {
        if (entryMatch.reuseHierarchy == 0 && entryMatch.significance == 0 && !isLoadingMatches) {
          let matches: Match = {
            id: undefined,
            entryTitle: undefined,
            entryDescription: undefined,
            solutionTitle: undefined,
            solutionDescription: undefined,
          };
          matches.id = entryMatch.id;
          matches.entryTitle = entryMatch.entryTitle;
          matches.entryDescription = entryMatch.entryDescription;
          matches.solutionTitle = entryMatch.solutionTitle;
          matches.solutionDescription = entryMatch.solutionDescription;
          innerMatches.push(matches);
        }
      });

      if (innerMatches.length > 0) {
        setNewMatches(innerMatches);
      }
    }
  }, [entryMatches]);

      
  // const onRefresh = () => {
  //   // @ts-ignore
  //   const refreshMatch: Match = {
  //     id: match?.id,
  //     entryTitle: match?.entryTitle,
  //     entryDescription: match?.entryDescription,
  //     solutionTitle: match?.solutionTitle,
  //     solutionDescription: match?.solutionDescription,
  //   };
  //   const matchesArray = [refreshMatch];
  //   // @ts-ignore
  //   dispatch(fetchXYGeneration(matchesArray, entryId, page));
  //   setActiveSolution(match?.id);
  // };

  const config = {
    columns: [ { key: 'solutionTitle', label: 'Title', mobileFriendly: true },
      { key: 'solutionDescription', label: 'Description', mobileFriendly: true },
      { key: 'significance', label: 'Significance of Impacts', mobileFriendly: true },
      { key: 'reuseHierarchy', label: 'Materials Reuse Hierarchy', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [6, 10, 20],
  };

  return (
          <div className="row align-items-start">
              <div className="col-xl-4 mb-3 matches__graph">
                  {isLoadingMatches && <div className="loading">
                      <span className="sr-only">Generating data, please wait.</span>
                  </div>}
                  {!isLoadingMatches && <Chart>
                    {entryMatches?.data.map(e => (
                      e.id !== activeSolution && (
                            <Pointer
                                key={e.id}
                                x={(e.significance / 100) * 500 + 20}
                                y={550 - (e.reuseHierarchy / 100) * 450}
                                isActive={false}
                            />
                      )
                    ))}
                    {match && (
                        <Pointer
                            key={match.id}
                            x={(match.significance / 100) * 500 + 20}
                            y={550 - (match.reuseHierarchy / 100) * 450}
                            isActive={true}
                        />
                    )}
                  </Chart>}
              </div>
            <div className="col-xl-8">
              <div className="row">
                 <DataTable paging={entryMatches?.paging || []} data={entryMatches?.data || []} config={config}
                           fetchFunction={fetchMatchesByEntry} baseSearch={{ 'entryId': entryId }}
                           isLoading={isLoadingMatches}
                           fetchParams={{ 'newMatches': newMatches, 'groupShortcode': groupShortcode, 'clientShortcode': 'clientShortcode' }}
                            dependencies={newMatches[0]?.solutionTitle}
                           isTable={false} lineItemTemplate={(row: any) => <div key={row.id} className="col-md-4" onClick={()=>onSolutionSelect(row.id)}> <Card onClick={() => toValuation(row.id)}
                                                                                  cardId={row.id}
                                                                                  title={row.solutionTitle}
                                                                                  description={row.solutionDescription}
                                                                                  isActive={activeSolution === row.id}

                >
                  {tags?.data
                    .filter(tag => row.inputTagValues.includes(tag.tagValue))
                    .map((matchedTag, tagIndex) => (
                          <Pill key={tagIndex} text={matchedTag.tagValue} type='black' icon='tag'/>
                    ))}
                </Card>
                </div>}
                />
              </div>
            </div>
          </div>
  );
};

export default ImpactOverview;