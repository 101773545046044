import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ENTRY_COMPOSITIONS_REQUEST,
  FETCH_ENTRY_COMPOSITIONS_RESPONSE,
  FETCH_ENTRY_COMPOSITIONS_FAILURE,
  FETCH_ENTRY_COMPOSITION_REQUEST,
  FETCH_ENTRY_COMPOSITION_RESPONSE,
  FETCH_ENTRY_COMPOSITION_FAILURE,
  POST_ENTRY_COMPOSITION_FAILURE,
  POST_ENTRY_COMPOSITION_REQUEST,
  POST_ENTRY_COMPOSITION_RESPONSE,
  EntryCompositionActionTypes,
  EntryCompositionDataModel,
  EntryComposition,
} from '../types/EntryComposition';

import { RootState } from '../store/configureStore';
import {
  archiveEntryComposition,
  unarchiveEntryComposition,
  getEntryComposition,
  getEntryCompositions,
  postEntryComposition, putEntryComposition,
} from '../api/EntryComposition';
import { postCompositionFailure, postCompositionRequest, postCompositionResponse } from './Composition';
import { DataTableParamsModel } from '../types/Common';

export function fetchEntryCompositionsRequest(): EntryCompositionActionTypes {
  return {
    type: FETCH_ENTRY_COMPOSITIONS_REQUEST,
    payload: null,
  };
}

export function fetchEntryCompositionsResponse(
  entryCompositions: EntryCompositionDataModel,
): EntryCompositionActionTypes {
  return {
    type: FETCH_ENTRY_COMPOSITIONS_RESPONSE,
    payload: entryCompositions,
  };
}

export function fetchEntryCompositionsFailure(): EntryCompositionActionTypes {
  return {
    type: FETCH_ENTRY_COMPOSITIONS_FAILURE,
    payload: null,
  };
}

export function fetchEntryCompositionRequest(): EntryCompositionActionTypes {
  return {
    type: FETCH_ENTRY_COMPOSITION_REQUEST,
    payload: null,
  };
}

export function fetchEntryCompositionResponse(
  entryComposition: EntryComposition,
): EntryCompositionActionTypes {
  return {
    type: FETCH_ENTRY_COMPOSITION_RESPONSE,
    payload: entryComposition,
  };
}

export function fetchEntryCompositionFailure(): EntryCompositionActionTypes {
  return {
    type: FETCH_ENTRY_COMPOSITION_FAILURE,
    payload: null,
  };
}

export function postEntryCompositionRequest(): EntryCompositionActionTypes {
  return {
    type: POST_ENTRY_COMPOSITION_REQUEST,
    payload: null,
  };
}

export function postEntryCompositionResponse(): EntryCompositionActionTypes {
  return {
    type: POST_ENTRY_COMPOSITION_RESPONSE,
    payload: null,
  };
}

export function postEntryCompositionFailure(error: string, validationErrors: any): EntryCompositionActionTypes {
  return {
    type: POST_ENTRY_COMPOSITION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveEntryComposition = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryCompositionRequest());
    try {
      const asyncResp: any = await getEntryComposition(id);
      if (asyncResp?.success) {
        dispatch(fetchEntryCompositionResponse(asyncResp.data));
      } else {
        dispatch(fetchEntryCompositionFailure());
      }
    } catch (error) {
      dispatch(fetchEntryCompositionFailure());
    }
  };

export const fetchEntryCompositions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryCompositionsRequest());
    try {
      const asyncResp: any = await getEntryCompositions(datatableParams);
      if (asyncResp?.success) {
        dispatch(fetchEntryCompositionsResponse(asyncResp));
      } else {
        dispatch(fetchEntryCompositionsFailure());
      }
    } catch (error) {
      dispatch(fetchEntryCompositionsFailure());
    }
  };

export const createEntryComposition = (
  entryId: string, compositionId: string, quantity: number, unitOfMeasure: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryCompositionRequest());
    try {
      const asyncResp: any = await postEntryComposition(entryId, compositionId, quantity, unitOfMeasure);
      if (asyncResp?.success) {
        dispatch(postEntryCompositionResponse());
      } else {
        dispatch(postEntryCompositionFailure(asyncResp?.error, asyncResp?.validationErrors));
      }
    } catch (error) {
      dispatch(postEntryCompositionFailure('An error occurred', null));
    }
  };

export const updateEntryComposition = (
  id: string,
  entryId: string, compositionId: string, quantity: number, unitOfMeasure: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postCompositionRequest());
    const asyncResp: any = await putEntryComposition(id, entryId, compositionId, quantity, unitOfMeasure);
    if (asyncResp?.success) {
      await dispatch(postCompositionResponse());
    } else {
      await dispatch(postCompositionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeEntryComposition = async (id: string) => {
  try {
    await archiveEntryComposition(id);
  } catch (error) {
    throw error;
  }
};

export const activateEntryComposition = async (id: string) => {
  try {
    await unarchiveEntryComposition(id);
  } catch (error) {
    throw error;
  }
};
