import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  Asset,
  AssetActionTypes,
  AssetDataModel,
  CLEAR_UPLOADED_ASSETS,
  FETCH_ASSET_FAILURE,
  FETCH_ASSET_REQUEST,
  FETCH_ASSET_RESPONSE,
  FETCH_ASSETS_FAILURE,
  FETCH_ASSETS_REQUEST,
  FETCH_ASSETS_RESPONSE,
  FETCH_ASSETS_DATA_FAILURE,
  FETCH_ASSETS_DATA_REQUEST,
  FETCH_ASSETS_DATA_RESPONSE,
  FETCH_DYNAMIC_ASSETS_REQUEST,
  FETCH_DYNAMIC_ASSETS_RESPONSE,
  FETCH_UPLOADED_ASSETS_RESPONSE,
  POST_ASSET_FAILURE,
  POST_ASSET_REQUEST,
  POST_ASSET_RESPONSE,
  UploadedAssetDataModel,
} from '../types/Asset';

import { RootState } from '../store/configureStore';

import { archiveAsset, getAsset, getAssets, postAsset, unarchiveAsset } from '../api/Asset';
import { DataTableParamsModel } from '../types/Common';

export function fetchAssetsRequest(): AssetActionTypes {
  return {
    type: FETCH_ASSETS_REQUEST,
    payload: null,
  };
}

export function fetchDynamicAssetsRequest(): AssetActionTypes {
  return {
    type: FETCH_DYNAMIC_ASSETS_REQUEST,
    payload: null,
  };
}

export function fetchAssetsResponse(
  assets: AssetDataModel,
): AssetActionTypes {
  return {
    type: FETCH_ASSETS_RESPONSE,
    payload: assets,
  };
}


export function fetchDynamicAssetsResponse(
  assets: AssetDataModel,
): AssetActionTypes {
  return {
    type: FETCH_DYNAMIC_ASSETS_RESPONSE,
    payload: assets,
  };
}

export function fetchUploadedAssetsResponse(
  assets: UploadedAssetDataModel, dynamicId : string,
): AssetActionTypes {
  return {
    type: FETCH_UPLOADED_ASSETS_RESPONSE,
    payload: assets,
    identifier: dynamicId,
  };
}

export function fetchAssetsFailure(): AssetActionTypes {
  return {
    type: FETCH_ASSETS_FAILURE,
    payload: null,
  };
}

export function fetchAssetsDataRequest(): AssetActionTypes {
  return {
    type: FETCH_ASSETS_DATA_REQUEST,
    payload: null,
  };
}

export function fetchAssetsDataResponse(
  assetsData: AssetDataModel,
): AssetActionTypes {
  return {
    type: FETCH_ASSETS_DATA_RESPONSE,
    payload: assetsData,
  };
}

export function fetchAssetsDataFailure(): AssetActionTypes {
  return {
    type: FETCH_ASSETS_DATA_FAILURE,
    payload: null,
  };
}

export function fetchAssetRequest(): AssetActionTypes {
  return {
    type: FETCH_ASSET_REQUEST,
    payload: null,
  };
}

export function fetchAssetResponse(
  asset: Asset,
): AssetActionTypes {
  return {
    type: FETCH_ASSET_RESPONSE,
    payload: asset,
  };
}

export function fetchAssetFailure(): AssetActionTypes {
  return {
    type: FETCH_ASSET_FAILURE,
    payload: null,
  };
}


export function postAssetRequest(): AssetActionTypes {
  return {
    type: POST_ASSET_REQUEST,
    payload: null,
  };
}

export function postAssetResponse(): AssetActionTypes {
  return {
    type: POST_ASSET_RESPONSE,
    payload: null,
  };
}

export function postAssetFailure(): AssetActionTypes {
  return {
    type: POST_ASSET_FAILURE,
    payload: null,
  };
}

export function clearUploadedAssets(): AssetActionTypes {
  return {
    type: CLEAR_UPLOADED_ASSETS,
    payload: null,
  };
}

export const fetchActiveAsset = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAssetRequest());
    const asyncResp: any = await getAsset(id);
    if (asyncResp?.success) {
      await dispatch(fetchAssetResponse(asyncResp.data));
    } else {
      await dispatch(fetchAssetFailure());
    }
  };

export const clearUpload = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(clearUploadedAssets());

  };

export const fetchAssets = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAssetsRequest());
    const asyncResp: any = await getAssets(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchAssetsResponse(asyncResp));
    } else {
      await dispatch(fetchAssetsFailure());
    }
  };

export const fetchAssetsData = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAssetsDataRequest());
    const asyncResp: any = await getAssets(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchAssetsDataResponse(asyncResp));
    } else {
      await dispatch(fetchAssetsDataFailure());
    }
  };

export const fetchDynamicAssets = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchDynamicAssetsRequest());
    const asyncResp: any = await getAssets(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchDynamicAssetsResponse(asyncResp));
    } else {
      await dispatch(fetchAssetsFailure());
    }
  };


export const createAsset = (
  groupId: string, clientId: string, userId: string, title: string, fileFormData: FormData, dynamicId?: string,
):

ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {

    dispatch(postAssetRequest());
    const asyncResp: any = await postAsset(groupId, clientId, userId, title, fileFormData);
    if (asyncResp?.success) {
      await dispatch(postAssetResponse());
      await dispatch(fetchAssets({}));
      await dispatch(fetchUploadedAssetsResponse(asyncResp, dynamicId ?? ''));
    } else {
      await dispatch(postAssetFailure());
    }
    return asyncResp;
  };

export const storeAsset = async (id: string) => {
  try {
    await archiveAsset(id);
  } catch (error) {
    throw error;
  }
};

export const activateAsset = async (id: string) => {
  try {
    await unarchiveAsset(id);
  } catch (error) {
    throw error;
  }
};
