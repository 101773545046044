import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ENTRY_FIELDS_REQUEST,
  FETCH_ENTRY_FIELDS_RESPONSE,
  FETCH_ENTRY_FIELDS_FAILURE,
  FETCH_ALL_ENTRY_FIELDS_REQUEST,
  FETCH_ALL_ENTRY_FIELDS_RESPONSE,
  FETCH_ALL_ENTRY_FIELDS_FAILURE,
  FETCH_ENTRY_FIELD_REQUEST,
  FETCH_ENTRY_FIELD_RESPONSE,
  FETCH_ENTRY_FIELD_FAILURE,
  POST_ENTRY_FIELD_FAILURE,
  POST_ENTRY_FIELD_REQUEST,
  POST_ENTRY_FIELD_RESPONSE,
  EntryFieldActionTypes, EntryFieldDataModel, EntryField,
} from '../types/EntryField';

import { RootState } from '../store/configureStore';

import {
  archiveEntryField,
  unarchiveEntryField,
  getEntryField,
  getEntryFields,
  getEntryFieldsByShortcodes,
  postEntryField,
  putEntryField,
} from '../api/EntryField';
import { DataTableParamsModel } from '../types/Common';

export function fetchEntryFieldsRequest(): EntryFieldActionTypes {
  return {
    type: FETCH_ENTRY_FIELDS_REQUEST,
    payload: null,
  };
}

export function fetchEntryFieldsResponse(
  entryFields: EntryFieldDataModel,
): EntryFieldActionTypes {
  return {
    type: FETCH_ENTRY_FIELDS_RESPONSE,
    payload: entryFields,
  };
}

export function fetchEntryFieldsFailure(): EntryFieldActionTypes {
  return {
    type: FETCH_ENTRY_FIELDS_FAILURE,
    payload: null,
  };
}

//
export function fetchAllEntryFieldsRequest(): EntryFieldActionTypes {
  return {
    type: FETCH_ALL_ENTRY_FIELDS_REQUEST,
    payload: null,
  };
}

export function fetchAllEntryFieldsResponse(
  entryFields: EntryFieldDataModel,
): EntryFieldActionTypes {
  return {
    type: FETCH_ALL_ENTRY_FIELDS_RESPONSE,
    payload: entryFields,
  };
}

export function fetchAllEntryFieldsFailure(): EntryFieldActionTypes {
  return {
    type: FETCH_ALL_ENTRY_FIELDS_FAILURE,
    payload: null,
  };
}
//

export function fetchEntryFieldRequest(): EntryFieldActionTypes {
  return {
    type: FETCH_ENTRY_FIELD_REQUEST,
    payload: null,
  };
}

export function fetchEntryFieldResponse(
  entryFields: EntryField,
): EntryFieldActionTypes {
  return {
    type: FETCH_ENTRY_FIELD_RESPONSE,
    payload: entryFields,
  };
}

export function fetchEntryFieldFailure(): EntryFieldActionTypes {
  return {
    type: FETCH_ENTRY_FIELD_FAILURE,
    payload: null,
  };
}


export function postEntryFieldRequest(): EntryFieldActionTypes {
  return {
    type: POST_ENTRY_FIELD_REQUEST,
    payload: null,
  };
}

export function postEntryFieldResponse(): EntryFieldActionTypes {
  return {
    type: POST_ENTRY_FIELD_RESPONSE,
    payload: null,
  };
}

export function postEntryFieldFailure(error: string, validationErrors: any): EntryFieldActionTypes {
  return {
    type: POST_ENTRY_FIELD_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveEntryField = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryFieldRequest());
    const asyncResp: any = await getEntryField(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryFieldResponse(asyncResp.data));
    } else {
      await dispatch(fetchEntryFieldFailure());
    }
  };

export const fetchEntryFields = (datatableParams?: DataTableParamsModel, visibilityPublic: boolean = false):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryFieldsRequest());
    const asyncResp: any = await getEntryFields(datatableParams, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchEntryFieldsResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFieldsFailure());
    }
  };

export const fetchAllEntryFields = (datatableParams?: DataTableParamsModel, visibilityPublic: boolean = false):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAllEntryFieldsRequest());
    const asyncResp: any = await getEntryFields(datatableParams, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchAllEntryFieldsResponse(asyncResp));
    } else {
      await dispatch(fetchAllEntryFieldsFailure());
    }
  };

export const fetchEntryFieldsByShortcodes = (groupShortcode: string, clientShortcode: string, entryId: string | null = null):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryFieldsRequest());
    const asyncResp: any = await getEntryFieldsByShortcodes(groupShortcode, clientShortcode, entryId);
    if (asyncResp?.success) {
      await dispatch(fetchEntryFieldsResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFieldsFailure());
    }
  };

export const storeEntryField = async (id: string) => {
  try {
    await archiveEntryField(id);
  } catch (error) {
    throw error;
  }
};

export const activateEntryField = async (id: string) => {
  try {
    await unarchiveEntryField(id);
  } catch (error) {
    throw error;
  }
};

export const createEntryField = (
  groupId: string, clientId: string, entryId: string, fieldId: string, order: number, value: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryFieldRequest());
    const asyncResp: any = await postEntryField(groupId, clientId, entryId, fieldId, order, value, active);
    if (asyncResp?.success) {
      await dispatch(postEntryFieldResponse());
    } else {
      await dispatch(postEntryFieldFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateEntryField = (
  id: string,
  groupId: string,
  clientId: string,
  entryId: string,
  fieldId: string,
  order: number,
  value: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryFieldRequest());
    const asyncResp: any = await putEntryField(id, groupId, clientId, entryId, fieldId, order, value, active);
    if (asyncResp?.success) {
      await dispatch(postEntryFieldResponse());
    } else {
      await dispatch(postEntryFieldFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


