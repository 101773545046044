import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SOLUTION_FUNCTIONS_REQUEST,
  FETCH_SOLUTION_FUNCTIONS_RESPONSE,
  FETCH_SOLUTION_FUNCTIONS_FAILURE,
  FETCH_SOLUTION_FUNCTION_REQUEST,
  FETCH_SOLUTION_FUNCTION_RESPONSE,
  FETCH_SOLUTION_FUNCTION_FAILURE,
  POST_SOLUTION_FUNCTION_FAILURE,
  POST_SOLUTION_FUNCTION_REQUEST,
  POST_SOLUTION_FUNCTION_RESPONSE,
  SolutionFunctionActionTypes, SolutionFunctionDataModel, SolutionFunction,
} from '../types/SolutionFunction';

import { RootState } from '../store/configureStore';

import { archiveSolutionFunction, unarchiveSolutionFunction, getSolutionFunction, getSolutionFunctions, postSolutionFunction, putSolutionFunction } from '../api/SolutionFunction';
import { DataTableParamsModel } from '../types/Common';

export function fetchSolutionFunctionsRequest(): SolutionFunctionActionTypes {
  return {
    type: FETCH_SOLUTION_FUNCTIONS_REQUEST,
    payload: null,
  };
}

export function fetchSolutionFunctionsResponse(
  solutionFunctions: SolutionFunctionDataModel,
): SolutionFunctionActionTypes {
  return {
    type: FETCH_SOLUTION_FUNCTIONS_RESPONSE,
    payload: solutionFunctions,
  };
}

export function fetchSolutionFunctionsFailure(): SolutionFunctionActionTypes {
  return {
    type: FETCH_SOLUTION_FUNCTIONS_FAILURE,
    payload: null,
  };
}

export function fetchSolutionFunctionRequest(): SolutionFunctionActionTypes {
  return {
    type: FETCH_SOLUTION_FUNCTION_REQUEST,
    payload: null,
  };
}

export function fetchSolutionFunctionResponse(
  solutionFunction: SolutionFunction,
): SolutionFunctionActionTypes {
  return {
    type: FETCH_SOLUTION_FUNCTION_RESPONSE,
    payload: solutionFunction,
  };
}

export function fetchSolutionFunctionFailure(): SolutionFunctionActionTypes {
  return {
    type: FETCH_SOLUTION_FUNCTION_FAILURE,
    payload: null,
  };
}


export function postSolutionFunctionRequest(): SolutionFunctionActionTypes {
  return {
    type: POST_SOLUTION_FUNCTION_REQUEST,
    payload: null,
  };
}

export function postSolutionFunctionResponse(): SolutionFunctionActionTypes {
  return {
    type: POST_SOLUTION_FUNCTION_RESPONSE,
    payload: null,
  };
}

export function postSolutionFunctionFailure(error: string, validationErrors: any): SolutionFunctionActionTypes {
  return {
    type: POST_SOLUTION_FUNCTION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveSolutionFunction = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionFunctionRequest());
    const asyncResp: any = await getSolutionFunction(id);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionFunctionResponse(asyncResp.data));
    } else {
      await dispatch(fetchSolutionFunctionFailure());
    }
  };

export const fetchSolutionFunctions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSolutionFunctionsRequest());
    const asyncResp: any = await getSolutionFunctions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchSolutionFunctionsResponse(asyncResp));
    } else {
      await dispatch(fetchSolutionFunctionsFailure());
    }
  };

export const storeSolutionFunction = async (id: string) => {
  try {
    await archiveSolutionFunction(id);
  } catch (error) {
    throw error;
  }
};

export const activateSolutionFunction = async (id: string) => {
  try {
    await unarchiveSolutionFunction(id);
  } catch (error) {
    throw error;
  }
};

export const createSolutionFunction = (
  functionId: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionFunctionRequest());
    const asyncResp: any = await postSolutionFunction(functionId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionFunctionResponse());
    } else {
      await dispatch(postSolutionFunctionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateSolutionFunction = (
  id: string,
  functionId: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postSolutionFunctionRequest());
    const asyncResp: any = await putSolutionFunction(id, functionId, active);
    if (asyncResp?.success) {
      await dispatch(postSolutionFunctionResponse());
    } else {
      await dispatch(postSolutionFunctionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


