import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_FIELD_TYPE_OPTIONS_REQUEST,
  FETCH_FIELD_TYPE_OPTIONS_RESPONSE,
  FETCH_FIELD_TYPE_OPTIONS_FAILURE,
  FETCH_FIELD_TYPE_OPTION_REQUEST,
  FETCH_FIELD_TYPE_OPTION_RESPONSE,
  FETCH_FIELD_TYPE_OPTION_FAILURE,
  POST_FIELD_TYPE_OPTION_FAILURE,
  POST_FIELD_TYPE_OPTION_REQUEST,
  POST_FIELD_TYPE_OPTION_RESPONSE,
  FieldTypeOptionActionTypes, FieldTypeOptionDataModel, FieldTypeOption,
} from '../types/FieldTypeOption';

import { RootState } from '../store/configureStore';

import { archiveFieldTypeOption, unarchiveFieldTypeOption, getFieldTypeOption, getFieldTypeOptions, postFieldTypeOption, putFieldTypeOption } from '../api/FieldTypeOption';
import { DataTableParamsModel } from '../types/Common';

export function fetchFieldTypeOptionsRequest(): FieldTypeOptionActionTypes {
  return {
    type: FETCH_FIELD_TYPE_OPTIONS_REQUEST,
    payload: null,
  };
}

export function fetchFieldTypeOptionsResponse(
  fieldTypeOptions: FieldTypeOptionDataModel,
): FieldTypeOptionActionTypes {
  return {
    type: FETCH_FIELD_TYPE_OPTIONS_RESPONSE,
    payload: fieldTypeOptions,
  };
}

export function fetchFieldTypeOptionsFailure(): FieldTypeOptionActionTypes {
  return {
    type: FETCH_FIELD_TYPE_OPTIONS_FAILURE,
    payload: null,
  };
}

export function fetchFieldTypeOptionRequest(): FieldTypeOptionActionTypes {
  return {
    type: FETCH_FIELD_TYPE_OPTION_REQUEST,
    payload: null,
  };
}

export function fetchFieldTypeOptionResponse(
  fieldTypeOptions: FieldTypeOption,
): FieldTypeOptionActionTypes {
  return {
    type: FETCH_FIELD_TYPE_OPTION_RESPONSE,
    payload: fieldTypeOptions,
  };
}

export function fetchFieldTypeOptionFailure(): FieldTypeOptionActionTypes {
  return {
    type: FETCH_FIELD_TYPE_OPTION_FAILURE,
    payload: null,
  };
}


export function postFieldTypeOptionRequest(): FieldTypeOptionActionTypes {
  return {
    type: POST_FIELD_TYPE_OPTION_REQUEST,
    payload: null,
  };
}

export function postFieldTypeOptionResponse(): FieldTypeOptionActionTypes {
  return {
    type: POST_FIELD_TYPE_OPTION_RESPONSE,
    payload: null,
  };
}

export function postFieldTypeOptionFailure(error: string, validationErrors: any): FieldTypeOptionActionTypes {
  return {
    type: POST_FIELD_TYPE_OPTION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveFieldTypeOption = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldTypeOptionRequest());
    const asyncResp: any = await getFieldTypeOption(id);
    if (asyncResp?.success) {
      await dispatch(fetchFieldTypeOptionResponse(asyncResp.data));
    } else {
      await dispatch(fetchFieldTypeOptionFailure());
    }
  };

export const fetchFieldTypeOptions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldTypeOptionsRequest());
    const asyncResp: any = await getFieldTypeOptions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchFieldTypeOptionsResponse(asyncResp));
    } else {
      await dispatch(fetchFieldTypeOptionsFailure());
    }
  };

export const storeFieldTypeOption = async (id: string) => {
  try {
    await archiveFieldTypeOption(id);
  } catch (error) {
    throw error;
  }
};

export const activateFieldTypeOption = async (id: string) => {
  try {
    await unarchiveFieldTypeOption(id);
  } catch (error) {
    throw error;
  }
};

export const createFieldTypeOption = (
  parentId: string, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldTypeOptionRequest());
    const asyncResp: any = await postFieldTypeOption(parentId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFieldTypeOptionResponse());
    } else {
      await dispatch(postFieldTypeOptionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateFieldTypeOption = (
  id: string,
  parentId: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldTypeOptionRequest());
    const asyncResp: any = await putFieldTypeOption(id, parentId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFieldTypeOptionResponse());
    } else {
      await dispatch(postFieldTypeOptionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


