import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getContacts(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.CONTACT,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getContact(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONTACT}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postContact(firstName: string, lastName: string, email: string, phoneNumber: string, companyName: string) {
  return (
    axios({
      url: c.API_ENDPOINTS.CONTACT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putContact(
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  companyName: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONTACT}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveContact(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONTACT}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveContact(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CONTACT}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
