import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_FIELD_TYPES_REQUEST,
  FETCH_FIELD_TYPES_RESPONSE,
  FETCH_FIELD_TYPES_FAILURE,
  FETCH_FIELD_TYPE_REQUEST,
  FETCH_FIELD_TYPE_RESPONSE,
  FETCH_FIELD_TYPE_FAILURE,
  POST_FIELD_TYPE_FAILURE,
  POST_FIELD_TYPE_REQUEST,
  POST_FIELD_TYPE_RESPONSE,
  FieldTypeActionTypes, FieldTypeDataModel, FieldType,
} from '../types/FieldType';

import { RootState } from '../store/configureStore';

import { getFieldType, getFieldTypes, postFieldType, putFieldType, archiveFieldType, unarchiveFieldType } from '../api/FieldType';
import { DataTableParamsModel } from '../types/Common';

export function fetchFieldTypesRequest(): FieldTypeActionTypes {
  return {
    type: FETCH_FIELD_TYPES_REQUEST,
    payload: null,
  };
}

export function fetchFieldTypesResponse(
  fieldTypes: FieldTypeDataModel,
): FieldTypeActionTypes {
  return {
    type: FETCH_FIELD_TYPES_RESPONSE,
    payload: fieldTypes,
  };
}

export function fetchFieldTypesFailure(): FieldTypeActionTypes {
  return {
    type: FETCH_FIELD_TYPES_FAILURE,
    payload: null,
  };
}

export function fetchFieldTypeRequest(): FieldTypeActionTypes {
  return {
    type: FETCH_FIELD_TYPE_REQUEST,
    payload: null,
  };
}

export function fetchFieldTypeResponse(
  fieldType: FieldType,
): FieldTypeActionTypes {
  return {
    type: FETCH_FIELD_TYPE_RESPONSE,
    payload: fieldType,
  };
}

export function fetchFieldTypeFailure(): FieldTypeActionTypes {
  return {
    type: FETCH_FIELD_TYPE_FAILURE,
    payload: null,
  };
}


export function postFieldTypeRequest(): FieldTypeActionTypes {
  return {
    type: POST_FIELD_TYPE_REQUEST,
    payload: null,
  };
}

export function postFieldTypeResponse(): FieldTypeActionTypes {
  return {
    type: POST_FIELD_TYPE_RESPONSE,
    payload: null,
  };
}

export function postFieldTypeFailure(error: string, validationErrors: any): FieldTypeActionTypes {
  return {
    type: POST_FIELD_TYPE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveFieldType = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldTypeRequest());
    const asyncResp: any = await getFieldType(id);
    if (asyncResp?.success) {
      await dispatch(fetchFieldTypeResponse(asyncResp.data));
    } else {
      await dispatch(fetchFieldTypeFailure());
    }
  };

export const fetchFieldTypes = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldTypesRequest());
    const asyncResp: any = await getFieldTypes(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchFieldTypesResponse(asyncResp));
    } else {
      await dispatch(fetchFieldTypesFailure());
    }
  };

export const storeFieldType = async (id: string) => {
  try {
    await archiveFieldType(id);
  } catch (error) {
    throw error;
  }
};

export const activateFieldType = async (id: string) => {
  try {
    await unarchiveFieldType(id);
  } catch (error) {
    throw error;
  }
};

export const createFieldType = (
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldTypeRequest());
    const asyncResp: any = await postFieldType(title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFieldTypeResponse());
    } else {
      await dispatch(postFieldTypeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateFieldType = (
  id: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldTypeRequest());
    const asyncResp: any = await putFieldType(id, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postFieldTypeResponse());
    } else {
      await dispatch(postFieldTypeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
