import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getSolutionBadge(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION_BADGE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getSolutionBadges(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.SOLUTION_BADGE,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postSolutionBadge(badgeId: string, solutionId: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.SOLUTION_BADGE,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        badgeId,
        solutionId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putSolutionBadge(
  id: string,
  badgeId: string,
  solutionId: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION_BADGE}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        badgeId,
        solutionId,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveSolutionBadge(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION_BADGE}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveSolutionBadge(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SOLUTION_BADGE}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

