import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_RESPONSE,
  FETCH_PLANS_FAILURE,
  FETCH_PLAN_REQUEST,
  FETCH_PLAN_RESPONSE,
  FETCH_PLAN_FAILURE,
  POST_PLAN_FAILURE,
  POST_PLAN_REQUEST,
  POST_PLAN_RESPONSE,
  PlanActionTypes, PlanDataModel, Plan,
} from '../types/Plan';

import { RootState } from '../store/configureStore';

import { archivePlan, unarchivePlan, getPlan, getPlans, postPlan, putPlan } from '../api/Plan';
import { DataTableParamsModel } from '../types/Common';

export function fetchPlansRequest(): PlanActionTypes {
  return {
    type: FETCH_PLANS_REQUEST,
    payload: null,
  };
}

export function fetchPlansResponse(
  plans: PlanDataModel,
): PlanActionTypes {
  return {
    type: FETCH_PLANS_RESPONSE,
    payload: plans,
  };
}

export function fetchPlansFailure(): PlanActionTypes {
  return {
    type: FETCH_PLANS_FAILURE,
    payload: null,
  };
}

export function fetchPlanRequest(): PlanActionTypes {
  return {
    type: FETCH_PLAN_REQUEST,
    payload: null,
  };
}

export function fetchPlanResponse(
  plan: Plan,
): PlanActionTypes {
  return {
    type: FETCH_PLAN_RESPONSE,
    payload: plan,
  };
}

export function fetchPlanFailure(): PlanActionTypes {
  return {
    type: FETCH_PLAN_FAILURE,
    payload: null,
  };
}

export function postPlanRequest(): PlanActionTypes {
  return {
    type: POST_PLAN_REQUEST,
    payload: null,
  };
}

export function postPlanResponse(): PlanActionTypes {
  return {
    type: POST_PLAN_RESPONSE,
    payload: null,
  };
}

export function postPlanFailure(error: string, validationErrors: any): PlanActionTypes {
  return {
    type: POST_PLAN_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActivePlan = (id: string, dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPlanRequest());
    const asyncResp: any = await getPlan(id, dataTableParams);
    if (asyncResp?.success) {
      await dispatch(fetchPlanResponse(asyncResp.data));
    } else {
      await dispatch(fetchPlanFailure());
    }
  };

export const fetchPlans = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPlansRequest());
    const asyncResp: any = await getPlans(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchPlansResponse(asyncResp));
    } else {
      await dispatch(fetchPlansFailure());
    }
  };

export const storePlan = async (id: string) => {
  try {
    await archivePlan(id);
  } catch (error) {
    throw error;
  }
};

export const activatePlan = async (id: string) => {
  try {
    await unarchivePlan(id);
  } catch (error) {
    throw error;
  }
};

export const createPlan = (
  title: string, description: string, clientId: string, monthlyFee: number,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postPlanRequest());
    const asyncResp: any = await postPlan(title, description, clientId, monthlyFee);
    if (asyncResp?.success) {
      await dispatch(postPlanResponse());
    } else {
      await dispatch(postPlanFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


export const updatePlan = (
  id: string,
  title: string, description: string, clientId: string, monthlyFee: number,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postPlanRequest());
    const asyncResp: any = await putPlan(id, title, description, clientId, monthlyFee);
    if (asyncResp?.success) {
      await dispatch(postPlanResponse());
    } else {
      await dispatch(postPlanFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
