import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function postSubscriptionTransaction(
  planId: string,
  clientId: string,
  tier: string,
  price: number,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.SUBSCRIPTION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        planId,
        clientId,
        tier,
        price,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

// Fetch a single subscription by ID
export function getSubscription(id: string, dataTableParams?: DataTableParamsModel) {
  const url = `${c.API_ENDPOINTS.SUBSCRIPTION_ADMIN}/${id}`;

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: dataTableParams,
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}

// Fetch all subscriptions
export function getSubscriptions(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.SUBSCRIPTION_ADMIN,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}

// Create a new subscription
export function postSubscription(
  planId: string,
  clientId: string,
  transactionId: string,
  startDate: string,
  endDate: string | null,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.SUBSCRIPTION_ADMIN,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        planId,
        clientId,
        transactionId,
        startDate,
        endDate,
      },
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}

// Update an existing subscription
export function putSubscription(
  id: string,
  planId: string,
  clientId: string,
  transactionId: string,
  startDate: string,
  endDate: string | null,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SUBSCRIPTION_ADMIN}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        id,
        planId,
        clientId,
        transactionId,
        startDate,
        endDate,
      },
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}

// Archive (delete) a subscription
export function archiveSubscription(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SUBSCRIPTION_ADMIN}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => response?.data)
      .catch((error: any) => error.response?.data)
  );
}

export function unarchiveSubscription(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.SUBSCRIPTION}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
