import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_OFFER_ITEMS_REQUEST,
  FETCH_OFFER_ITEMS_RESPONSE,
  FETCH_OFFER_ITEMS_FAILURE,
  FETCH_OFFER_ITEM_REQUEST,
  FETCH_OFFER_ITEM_RESPONSE,
  FETCH_OFFER_ITEM_FAILURE,
  POST_OFFER_ITEM_FAILURE,
  POST_OFFER_ITEM_REQUEST,
  POST_OFFER_ITEM_RESPONSE,
  OfferItemActionTypes, OfferItemDataModel, OfferItem,
} from '../types/OfferItem';

import { RootState } from '../store/configureStore';

import { getOfferItem, getOfferItems, postOfferItem, putOfferItem, archiveOfferItem, unarchiveOfferItem } from '../api/OfferItem';
import { DataTableParamsModel } from '../types/Common';

export function fetchOfferItemsRequest(): OfferItemActionTypes {
  return {
    type: FETCH_OFFER_ITEMS_REQUEST,
    payload: null,
  };
}

export function fetchOfferItemsResponse(
  offerItems: OfferItemDataModel,
): OfferItemActionTypes {
  return {
    type: FETCH_OFFER_ITEMS_RESPONSE,
    payload: offerItems,
  };
}

export function fetchOfferItemsFailure(): OfferItemActionTypes {
  return {
    type: FETCH_OFFER_ITEMS_FAILURE,
    payload: null,
  };
}

export function fetchOfferItemRequest(): OfferItemActionTypes {
  return {
    type: FETCH_OFFER_ITEM_REQUEST,
    payload: null,
  };
}

export function fetchOfferItemResponse(
  offerItem: OfferItem,
): OfferItemActionTypes {
  return {
    type: FETCH_OFFER_ITEM_RESPONSE,
    payload: offerItem,
  };
}

export function fetchOfferItemFailure(): OfferItemActionTypes {
  return {
    type: FETCH_OFFER_ITEM_FAILURE,
    payload: null,
  };
}

export const fetchActiveOfferItem = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferItemRequest());
    const asyncResp: any = await getOfferItem(id);
    if (asyncResp?.success) {
      await dispatch(fetchOfferItemResponse(asyncResp.data));
    } else {
      await dispatch(fetchOfferItemFailure());
    }
  };

export function postOfferItemRequest(): OfferItemActionTypes {
  return {
    type: POST_OFFER_ITEM_REQUEST,
    payload: null,
  };
}

export function postOfferItemResponse(): OfferItemActionTypes {
  return {
    type: POST_OFFER_ITEM_RESPONSE,
    payload: null,
  };
}

export function postOfferItemFailure(error: string, validationErrors: any): OfferItemActionTypes {
  return {
    type: POST_OFFER_ITEM_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchOfferItems = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOfferItemsRequest());
    const asyncResp: any = await getOfferItems(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchOfferItemsResponse(asyncResp));
    } else {
      await dispatch(fetchOfferItemsFailure());
    }
  };

export const createOfferItem = (
  offerId: string, entryId: string, quantity: number, minRate: number, maxRate: number, actualRate: number, details: string | null,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferItemRequest());
    const asyncResp: any = await postOfferItem(offerId, entryId, quantity, minRate, maxRate, actualRate, details);
    if (asyncResp?.success) {
      await dispatch(postOfferItemResponse());
    } else {
      await dispatch(postOfferItemFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateOfferItem = (
  id: string,
  offerId: string, entryId: string, quantity: number, minRate: number, maxRate: number, actualRate: number, details: string | null,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOfferItemRequest());
    const asyncResp: any = await putOfferItem(id, offerId, entryId, quantity, minRate, maxRate, actualRate, details);
    if (asyncResp?.success) {
      await dispatch(postOfferItemResponse());
    } else {
      await dispatch(postOfferItemFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const storeOfferItem = async (id: string) => {
  try {
    await archiveOfferItem(id);
  } catch (error) {
    throw error;
  }
};

export const activateOfferItem = async (id: string) => {
  try {
    await unarchiveOfferItem(id);
  } catch (error) {
    throw error;
  }
};
