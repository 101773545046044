import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_MATCHES_REQUEST,
  FETCH_MATCHES_RESPONSE,
  FETCH_MATCHES_FAILURE,
  FETCH_MATCH_REQUEST,
  FETCH_MATCH_RESPONSE,
  FETCH_MATCH_FAILURE,
  POST_MATCH_FAILURE,
  POST_MATCH_REQUEST,
  POST_MATCH_RESPONSE,
  MatchActionTypes, MatchDataModel, Match,
} from '../types/Match';

import { RootState } from '../store/configureStore';

import {
  archiveMatch,
  unarchiveMatch,
  getMatch,
  getMatches,
  getMatchesByEntry,
  makeMatchesByEntry,
  postMatch,
  putMatch,
} from '../api/Match';
import { DataTableParamsModel } from '../types/Common';

export function fetchMatchesRequest(): MatchActionTypes {
  return {
    type: FETCH_MATCHES_REQUEST,
    payload: null,
  };
}

export function fetchMatchesResponse(
  matches: MatchDataModel,
): MatchActionTypes {
  return {
    type: FETCH_MATCHES_RESPONSE,
    payload: matches,
  };
}

export function fetchMatchesFailure(): MatchActionTypes {
  return {
    type: FETCH_MATCHES_FAILURE,
    payload: null,
  };
}

export function fetchMatchRequest(): MatchActionTypes {
  return {
    type: FETCH_MATCH_REQUEST,
    payload: null,
  };
}

export function fetchMatchResponse(
  match: Match,
): MatchActionTypes {
  return {
    type: FETCH_MATCH_RESPONSE,
    payload: match,
  };
}

export function fetchMatchFailure(): MatchActionTypes {
  return {
    type: FETCH_MATCH_FAILURE,
    payload: null,
  };
}


export function postMatchRequest(): MatchActionTypes {
  return {
    type: POST_MATCH_REQUEST,
    payload: null,
  };
}

export function postMatchResponse(): MatchActionTypes {
  return {
    type: POST_MATCH_RESPONSE,
    payload: null,
  };
}

export function postMatchFailure(error: string, validationErrors: any): MatchActionTypes {
  return {
    type: POST_MATCH_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveMatch = (id: string, dataTableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMatchRequest());
    const asyncResp: any = await getMatch(id, dataTableParams);
    if (asyncResp?.success) {
      await dispatch(fetchMatchResponse(asyncResp.data));
    } else {
      await dispatch(fetchMatchFailure());
    }
  };

export const fetchMatches = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMatchesRequest());
    const asyncResp: any = await getMatches(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchMatchesResponse(asyncResp));
    } else {
      await dispatch(fetchMatchesFailure());
    }
  };

export const matchMakeByEntry = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMatchRequest());
    const asyncResp: any = await makeMatchesByEntry(id);
    if (asyncResp?.success) {
      await dispatch(postMatchResponse());
    } else {
      await dispatch(postMatchFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const fetchMatchesByEntry = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMatchesRequest());
    const asyncResp: any = await getMatchesByEntry(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchMatchesResponse(asyncResp));
    } else {
      await dispatch(fetchMatchesFailure());
    }
  };

export const storeMatch = async (id: string) => {
  try {
    await archiveMatch(id);
  } catch (error) {
    throw error;
  }
};

export const activateMatch = async (id: string) => {
  try {
    await unarchiveMatch(id);
  } catch (error) {
    throw error;
  }
};

export const createMatch = (
  groupId: string, clientId: string, solutionId: string, entryId: string, status: string, impact: string,
  significance: number, reuseHierarchy: number,
  environmentalResponse: string, financialResponse: string, socialResponse: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMatchRequest());
    const asyncResp: any = await postMatch(groupId, clientId, solutionId, entryId, status, impact,
      significance, reuseHierarchy, environmentalResponse, financialResponse, socialResponse, active);
    if (asyncResp?.success) {
      await dispatch(postMatchResponse());
    } else {
      await dispatch(postMatchFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateMatch = (
  id: string,
  groupId: string,
  clientId: string,
  solutionId: string,
  entryId: string,
  status: string,
  impact: string,
  significance: number,
  reuseHierarchy: number,
  environmentalResponse: string,
  financialResponse: string,
  socialResponse: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMatchRequest());
    const asyncResp: any = await putMatch(id, groupId, clientId, solutionId, entryId, status, impact,
      significance, reuseHierarchy, environmentalResponse, financialResponse, socialResponse, active);
    if (asyncResp?.success) {
      await dispatch(postMatchResponse());
    } else {
      await dispatch(postMatchFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


