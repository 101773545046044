import React, { useEffect, useState } from 'react';
import './Input.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Validator } from '../../hooks/ValidationHook';
import gallery from '../AssetGallery/AssetGallery';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'; // Tooltip from Bootstrap
import { unitMappings, UnitType } from '../../helpers/constants';


type Props = {
  value?: string | number | null;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
  id?: string;
  label?: string;
  required?: boolean;
  subtitle?: string;
  type?: 'number' | 'text' | 'password' | 'tel';
  passportUnits?: UnitType | string;
  bgColor?: 'grey' | 'white';
  style?: 'rounded' | 'normal';
  disabled?: boolean;
  v?: any;
  err?: (id: string) => string[];
  validate?: any;
  validators?: Validator[];
  errors?: string[];
  maxLength?: number;
  autoFocus?: boolean;
  customRegex?: RegExp;
  readOnly?: boolean;
};

const Input = (props: Props) => {
  const {
    onBlur = () => {},
    onChange = () => {},
    onKeyDown = () => {},
    disabled = false,
    autoFocus = false,
    required = false,
    placeholder = '',
    passportUnits = '',
    value = '',
    name = '',
    subtitle = '',
    id = '',
    label = '',
    err = () => [],
    bgColor = 'grey',
    type = 'text',
    v = () => {},
    maxLength,
    customRegex,
    readOnly = false,
  } = props;

  const [touched, setTouched] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);

  const validateInput = (inputValue: string) => {
    if (customRegex) {
      return customRegex.test(inputValue);
    }
    if (type === 'tel') {
      return /^[+0-9 ]*$/.test(inputValue);
    }
    return true;
  };

  const innerOnChange = (e: any) => {
    const inputValue = e.target.value;
    if (validateInput(inputValue)) {
      setTouched(true);
      onChange(inputValue);
      if (v && typeof v === 'function') {
        v(id, inputValue);
      }
    }
  };

  const innerOnBlur = (e: any) => {
    const inputValue = e.target.value;
    if (validateInput(inputValue)) {
      setTouched(true);
      onBlur(inputValue);
      if (v && typeof v === 'function') {
        v(id, inputValue);
      }
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const errors = err(id);

  let unitSymbol = '';

  if (passportUnits && !['meter', 'millimeter', 'centimeter'].includes(passportUnits)) {
    console.warn(`Invalid passportUnits value: ${passportUnits}`);
  } else if (passportUnits) {
    unitSymbol = unitMappings[passportUnits as UnitType];
  }

  const renderTooltip = (propsS: any) => (
      <Tooltip id="info-icon" {...propsS}>
        {subtitle || 'Info'}
      </Tooltip>
  );

  return (
      <div className={'input my-1'}>
        {label && (
            <label htmlFor={id} className="form-label">
              {label}
              <span className={`form-label ${errors.length > 0 && 'error-lbl'}`}>
            {required && '*'}
          </span>
            </label>
        )}
        {(subtitle && subtitle !== '.') && (
            <OverlayTrigger
                {...(props as any)}
                placement="right"
                overlay={renderTooltip}>
              <img className={'info-tooltip'} src={gallery.infoIcon2} alt="Example" />
            </OverlayTrigger>
        )}
        <div className="row">
          <div className="custom-input mb-2">
            <div className='input-group'>
            <input
                disabled={disabled}
                type={
                  type === 'text' || type === 'number' || type === 'tel'
                    ? type
                    : passwordShown
                      ? 'text'
                      : 'password'
                }
                required={required}
                className={`form-control ${bgColor} ${passportUnits && 'units'} ${
                  errors.length > 0 && 'error'
                }`}
                value={value || ''}
                name={name}
                id={id}
                onBlur={innerOnBlur}
                onChange={innerOnChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                maxLength={maxLength}
                autoFocus={autoFocus}
                readOnly={readOnly}
            />
              {unitSymbol && <div className="input-group-append">
                <span className="input-group-text"> {unitSymbol}</span>
              </div>}
            </div>
            {(errors.length > 0 && !unitSymbol) && (
                <img src={gallery.xImg} alt="" className="error-icon" />
            )}
            {type === 'password' && (
                <img
                    src={gallery.eyeImg}
                    alt=""
                    className="input-eye"
                    onClick={togglePassword}
                />
            )}
          </div>

          {errors.map((e: string, i: number) => (
              <div key={i} className="col-auto error-container">
                {e}
              </div>
          ))}
        </div>
      </div>
  );
};

export default Input;
