import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getAssetsData, isFetchingAssets } from '../../../selectors/Asset';
import { activateAsset, fetchAssetsData } from '../../../actions/Asset';
import DataTable from '../../../components/DataTable';
import { storeAsset } from '../../../actions/Asset';
import { Asset } from '../../../types/Asset';
import Modal from '../../../components/Modal';

const AssetDataTable = () => {

  const assets = useSelector(getAssetsData);
  const isLoading = useSelector(isFetchingAssets);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [asset, setActiveAsset] = useState<Asset | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onCreate = () => {
    history.push(c.APP_ROUTES.ASSET_CREATE);
  };

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onArchive = (row: Asset) => {
    setActiveAsset(row);
    setToggle(true);
  };

  const onUnArchive = (row: Asset) => {
    setActiveAsset(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (asset?.id) {
      if (unArchiveToggle){
        await activateAsset(asset?.id);
      } else {
        await storeAsset(asset?.id);
      }
      setRefetch(!refetch);
    }
    setActiveAsset(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'title', label: 'File Title', mobileFriendly: false },
      { key: 'mime', label: 'Mime', mobileFriendly: true },
      { key: 'id', label: 'Identifier', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [{ icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (<View title="Assets" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchAssetsData} data={assets?.data || []}
                   isLoading={isLoading} paging={assets?.paging || []} config={config} dependencies={refetch}/>
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Asset' : 'Archive Asset'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
    </View>
  );
};

export default AssetDataTable;

