import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_FIELD_PROPERTIES_REQUEST,
  FETCH_FIELD_PROPERTIES_RESPONSE,
  FETCH_FIELD_PROPERTIES_FAILURE,
  FETCH_FIELD_PROPERTY_REQUEST,
  FETCH_FIELD_PROPERTY_RESPONSE,
  FETCH_FIELD_PROPERTY_FAILURE,
  POST_FIELD_PROPERTY_FAILURE,
  POST_FIELD_PROPERTY_REQUEST,
  POST_FIELD_PROPERTY_RESPONSE,
  FieldPropertyActionTypes, FieldPropertyDataModel, FieldProperty,
} from '../types/FieldProperty';

import { RootState } from '../store/configureStore';

import {
  archiveFieldProperty,
  unarchiveFieldProperty,
  getFieldProperty,
  getFieldProperties,
  postFieldProperty,
  putFieldProperty,
  getFieldPropertiesByEntry, getFieldPropertiesByEntryByShortcodes,
} from '../api/FieldProperty';
import { DataTableParamsModel } from '../types/Common';

export function fetchFieldPropertiesRequest(): FieldPropertyActionTypes {
  return {
    type: FETCH_FIELD_PROPERTIES_REQUEST,
    payload: null,
  };
}

export function fetchFieldPropertiesResponse(
  fieldProperties: FieldPropertyDataModel,
): FieldPropertyActionTypes {
  return {
    type: FETCH_FIELD_PROPERTIES_RESPONSE,
    payload: fieldProperties,
  };
}

export function fetchFieldPropertiesFailure(): FieldPropertyActionTypes {
  return {
    type: FETCH_FIELD_PROPERTIES_FAILURE,
    payload: null,
  };
}

export function fetchFieldPropertyRequest(): FieldPropertyActionTypes {
  return {
    type: FETCH_FIELD_PROPERTY_REQUEST,
    payload: null,
  };
}

export function fetchFieldPropertyResponse(
  fieldProperties: FieldProperty,
): FieldPropertyActionTypes {
  return {
    type: FETCH_FIELD_PROPERTY_RESPONSE,
    payload: fieldProperties,
  };
}

export function fetchFieldPropertyFailure(): FieldPropertyActionTypes {
  return {
    type: FETCH_FIELD_PROPERTY_FAILURE,
    payload: null,
  };
}


export function postFieldPropertyRequest(): FieldPropertyActionTypes {
  return {
    type: POST_FIELD_PROPERTY_REQUEST,
    payload: null,
  };
}

export function postFieldPropertyResponse(): FieldPropertyActionTypes {
  return {
    type: POST_FIELD_PROPERTY_RESPONSE,
    payload: null,
  };
}

export function postFieldPropertyFailure(error: string, validationErrors: any): FieldPropertyActionTypes {
  return {
    type: POST_FIELD_PROPERTY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveFieldProperty = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldPropertyRequest());
    const asyncResp: any = await getFieldProperty(id);
    if (asyncResp?.success) {
      await dispatch(fetchFieldPropertyResponse(asyncResp.data));
    } else {
      await dispatch(fetchFieldPropertyFailure());
    }
  };

export const fetchFieldProperties = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldPropertiesRequest());
    const asyncResp: any = await getFieldProperties(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchFieldPropertiesResponse(asyncResp));
    } else {
      await dispatch(fetchFieldPropertiesFailure());
    }
  };

export const fetchFieldPropertiesByEntry = (entryId: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldPropertiesRequest());
    const asyncResp: any = await getFieldPropertiesByEntry(entryId);
    if (asyncResp?.success) {
      await dispatch(fetchFieldPropertiesResponse(asyncResp));
    } else {
      await dispatch(fetchFieldPropertiesFailure());
    }
  };

export const fetchFieldPropertiesByEntryByShortcodes = (groupShortcode: string, clientShortcode: string, entryId: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchFieldPropertiesRequest());
    const asyncResp: any = await getFieldPropertiesByEntryByShortcodes(groupShortcode, clientShortcode, entryId);
    if (asyncResp?.success) {
      await dispatch(fetchFieldPropertiesResponse(asyncResp));
    } else {
      await dispatch(fetchFieldPropertiesFailure());
    }
  };

export const storeFieldProperty = async (id: string) => {
  try {
    await archiveFieldProperty(id);
  } catch (error) {
    throw error;
  }
};

export const activateFieldProperty = async (id: string) => {
  try {
    await unarchiveFieldProperty(id);
  } catch (error) {
    throw error;
  }
};

export const createFieldProperty = (
  groupId: string, clientId: string, fieldTypeId: string, fieldId: string, fieldTypeOptionId: string, value: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldPropertyRequest());
    const asyncResp: any = await postFieldProperty(groupId, clientId, fieldTypeId, fieldId, fieldTypeOptionId, value, active);
    if (asyncResp?.success) {
      await dispatch(postFieldPropertyResponse());
    } else {
      await dispatch(postFieldPropertyFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateFieldProperty = (
  id: string,
  groupId: string,
  clientId: string,
  fieldTypeId: string,
  fieldId: string,
  fieldTypeOptionId: string,
  value: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postFieldPropertyRequest());
    const asyncResp: any = await putFieldProperty(id, groupId, clientId, fieldTypeId, fieldId, fieldTypeOptionId, value, active);
    if (asyncResp?.success) {
      await dispatch(postFieldPropertyResponse());
    } else {
      await dispatch(postFieldPropertyFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


