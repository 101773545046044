import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getUsers(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.USER,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getUser(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postUser(roleId: string, email: string, password: string, username: string, firstName: string,
  lastName: string, location: string, occupation: string, bio: string, avatar: string, active: boolean, verified: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.USER,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        roleId,
        email,
        password,
        username,
        firstName,
        lastName,
        location,
        occupation,
        bio,
        avatar,
        active,
        verified,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postUserRegister(roleId: string | null, email: string, password: string, username: string, firstName: string, lastName: string, active: boolean, verified: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/register`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        roleId,
        email,
        password,
        username,
        firstName,
        lastName,
        active,
        verified,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putUser(
  id: string,
  roleId: string,
  email: string,
  password: string,
  username: string,
  firstName: string,
  lastName: string,
  location: string,
  occupation: string,
  bio: string,
  avatar: string,
  active: boolean,
  verified: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        roleId,
        email,
        password,
        username,
        firstName,
        lastName,
        location,
        occupation,
        bio,
        avatar,
        active,
        verified,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveUser(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveUser(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postUserLogin(email: string, password: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/login`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        email,
        password,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postResetPassword(email: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/resetPassword`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        email,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putResetPassword(id: string, password: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/resetPassword/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        password,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putUpdatePassword(id: string, oldPassword: string, newPassword: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/updatePassword/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        oldPassword,
        newPassword,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
