import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getEntryEdges, isFetchingEntryEdges } from '../../../selectors/EntryEdge';
import { activateEntryEdge, fetchEntryEdges, storeEntryEdge } from '../../../actions/EntryEdge';
import { EntryEdge } from '../../../types/EntryEdge';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const EntryEdgeDataTable = () => {

  const entryEdges = useSelector(getEntryEdges);
  const isLoading = useSelector(isFetchingEntryEdges);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [entryEdge, setActiveEntryEdge] = useState<EntryEdge | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ENTRY_EDGE_CREATE);
  };

  const onArchive = (row: EntryEdge) => {
    setActiveEntryEdge(row);
    setToggle(true);
  };

  const onUnArchive = (row: EntryEdge) => {
    setActiveEntryEdge(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (entryEdge?.id) {
      if (unArchiveToggle){
        await activateEntryEdge(entryEdge?.id);
      } else {
        await storeEntryEdge(entryEdge?.id);
      }
      setRefetch(!refetch);
    }
    setActiveEntryEdge(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'fromEntryTitle', label: 'From Entry', mobileFriendly: true },
      { key: 'toEntryTitle', label: 'To Entry', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'createdBy', label: 'Created By', mobileFriendly: false },
    ],
    actions: [
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };
  return (
      <View title="Entry Links" onAddNew={onCreate}>
            <DataTable fetchFunction={fetchEntryEdges} data={entryEdges?.data || []}
                       isLoading={isLoading} paging={entryEdges?.paging || []} config={config} dependencies={refetch}/>
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
        </View>
  );
};

export default EntryEdgeDataTable;

