import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getOfferItems, isFetchingOfferItems } from '../../../selectors/OfferItem';
import { activateOfferItem, fetchOfferItems, storeOfferItem } from '../../../actions/OfferItem';
import { OfferItem } from '../../../types/OfferItem';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const OfferItemDataTable = () => {

  const offerItems = useSelector(getOfferItems);
  const isLoading = useSelector(isFetchingOfferItems);
  const [toggle, setToggle] = useState(false);
  const [offerItem, setActiveOfferItem] = useState<OfferItem | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.OFFER_ITEM_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.OFFER_ITEM_UPDATE, row.id));
  };

  const onArchive = (row: OfferItem) => {
    setActiveOfferItem(row);
    setToggle(true);
  };

  const onUnArchive = (row: OfferItem) => {
    setActiveOfferItem(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (offerItem?.id) {
      if (unArchiveToggle){
        await activateOfferItem(offerItem?.id);
      } else {
        await storeOfferItem(offerItem?.id);
      }
      setRefetch(!refetch);
    }
    setActiveOfferItem(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };
  
  const config = {
    columns: [
      { key: 'offerNumber', label: 'Offer', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: false },
      { key: 'quantity', label: 'Quantity', mobileFriendly: true },
      { key: 'minRate', label: 'Min Rate', mobileFriendly: true },
      { key: 'maxRate', label: 'Max Rate', mobileFriendly: true },
      { key: 'actualRate', label: 'Actual Rate', mobileFriendly: true },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Offer Items" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchOfferItems}
                data={offerItems?.data || []}
                isLoading={isLoading}
                paging={offerItems?.paging || []}
                config={config}
                dependencies={refetch}
            />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Offer Item' : 'Archive Offer Item'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this Offer Item?' : 'Are you sure you want to archive this Offer Item?'}
          </Modal>
        </View>
  );
};

export default OfferItemDataTable;
