import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getAuctionEntries, isFetchingAuctionEntries } from '../../../selectors/AuctionEntry';
import { storeAuctionEntry, fetchAuctionEntries, activateAuctionEntry } from '../../../actions/AuctionEntry';
import { AuctionEntry } from '../../../types/AuctionEntry';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';
import { formatUrl } from '../../../helpers/functions';

const AuctionEntryDataTable = () => {
  const auctionEntries = useSelector(getAuctionEntries);
  const isLoading = useSelector(isFetchingAuctionEntries);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [auctionEntry, setActiveAuctionEntry] = useState<AuctionEntry | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.AUCTION_ENTRY_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.AUCTION_ENTRY_UPDATE, row.id));
  };

  const onDelete = (row: AuctionEntry) => {
    setActiveAuctionEntry(row);
    setToggle(true);
  };

  const onUnArchive = (row: AuctionEntry) => {
    setActiveAuctionEntry(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (auctionEntry?.id) {
      if (unArchiveToggle){
        await activateAuctionEntry(auctionEntry?.id);
      } else {
        await storeAuctionEntry(auctionEntry?.id);
      }
      setRefetch(!refetch);
    }
    setActiveAuctionEntry(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'auctionTitle', label: 'Auction', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onDelete },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
      <View title="Auction/Entry" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchAuctionEntries}
                isLoading={isLoading}
                paging={auctionEntries?.paging || []}
                data={auctionEntries?.data || []}
                config={config}
                dependencies={refetch}
            />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Auction Entry' : 'Archive Auction Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this Auction Entry?' : 'Are you sure you want to archive this Auction Entry?'}
        </Modal>
        </View>
  );
};

export default AuctionEntryDataTable;
