import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getFieldType(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_TYPE}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getFieldTypes(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELD_TYPE,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postFieldType(title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELD_TYPE,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putFieldType(
  id: string,
  title: string,
  description: string | null,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_TYPE}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveFieldType(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_TYPE}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveFieldType(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_TYPE}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

