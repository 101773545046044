import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getEntryCompositions, isFetchingEntryCompositions } from '../../../selectors/EntryComposition';
import {
  activateEntryComposition,
  fetchEntryCompositions,
  storeEntryComposition,
} from '../../../actions/EntryComposition';
import { EntryComposition } from '../../../types/EntryComposition';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';
import { formatUrl } from '../../../helpers/functions';

const EntryCompositionDataTable = () => {
  
  const entryCompositions = useSelector(getEntryCompositions);
  const isLoading = useSelector(isFetchingEntryCompositions);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [entryComposition, setActiveEntryComposition] = useState<EntryComposition | null>(null);
  const [refetch, setRefetch] = useState(false);
  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ENTRY_COMPOSITION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_COMPOSITION_UPDATE, row.id));
  };

  const onArchive = (row: EntryComposition) => {
    setActiveEntryComposition(row);
    setToggle(true);
  };

  const onUnArchive = (row: EntryComposition) => {
    setActiveEntryComposition(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (entryComposition?.id) {
      if (unArchiveToggle){
        await activateEntryComposition(entryComposition?.id);
      } else {
        await storeEntryComposition(entryComposition?.id);
      }
      setRefetch(!refetch);
    }
    setActiveEntryComposition(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'compositionMaterial', label: 'Composition Material', mobileFriendly: true },
      { key: 'compositionVariation', label: 'Composition Variation', mobileFriendly: true },
      { key: 'quantity', label: 'Quantity', mobileFriendly: false },
      { key: 'unitOfMeasure', label: 'Unit of Measure', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: 'Edit', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (
        <View title="Entry Compositions" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchEntryCompositions}
                data={entryCompositions?.data || []}
                isLoading={isLoading}
                paging={entryCompositions?.paging}
                config={config}
                dependencies={refetch}
            />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
          </Modal>
        </View>
  );
};

export default EntryCompositionDataTable;
