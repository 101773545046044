import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  Entry,
  EntryActionTypes,
  EntryDataModel,
  EntryMatchDataModel,
  FETCH_ENTRIES_FAILURE,
  FETCH_ENTRIES_REQUEST,
  FETCH_ENTRIES_RESPONSE,
  FETCH_POPULAR_ENTRIES_FAILURE,
  FETCH_POPULAR_ENTRIES_REQUEST,
  FETCH_POPULAR_ENTRIES_RESPONSE,
  FETCH_RECENTLY_ADDED_ENTRIES_FAILURE,
  FETCH_RECENTLY_ADDED_ENTRIES_REQUEST,
  FETCH_RECENTLY_ADDED_ENTRIES_RESPONSE,
  FETCH_RECENTLY_REUSED_ENTRIES_FAILURE,
  FETCH_RECENTLY_REUSED_ENTRIES_REQUEST,
  FETCH_RECENTLY_REUSED_ENTRIES_RESPONSE,
  FETCH_ENTRY_FAILURE,
  FETCH_ENTRY_MATCHES_RESPONSE,
  FETCH_ENTRY_REQUEST,
  FETCH_ENTRY_RESPONSE,
  POST_ENTRY_FAILURE,
  POST_ENTRY_REQUEST,
  POST_ENTRY_RESPONSE,
} from '../types/Entry';

import { RootState } from '../store/configureStore';

import {
  archiveEntry, getCreateOfferEntries,
  getEntries,
  getEntriesByMarketplace,
  getEntriesByShortcodes,
  getEntry,
  getEntryByShortcode,
  getGuestEntryByShortcode, getNewEntries, getPublicEntries, getReusedEntries,
  matchEntry,
  postDuplicateEntry,
  postEntry,
  postEntryWithFields,
  putEntry,
  putEntryStatus,
  putEntryWithFields, unarchiveEntry,
} from '../api/Entry';
import { fetchEntryTimelineByShortcodes } from './EntryHistory';
import { fetchMarketplaceEntriesResponse } from './MarketplaceEntry';
import { DataTableParamsModel } from '../types/Common';

export function fetchEntriesRequest(): EntryActionTypes {
  return {
    type: FETCH_ENTRIES_REQUEST,
    payload: null,
  };
}

export function fetchEntriesResponse(
  entries: EntryDataModel,
): EntryActionTypes {
  return {
    type: FETCH_ENTRIES_RESPONSE,
    payload: entries,
  };
}

export function fetchEntriesFailure(): EntryActionTypes {
  return {
    type: FETCH_ENTRIES_FAILURE,
    payload: null,
  };
}

export function fetchEntryRequest(): EntryActionTypes {
  return {
    type: FETCH_ENTRY_REQUEST,
    payload: null,
  };
}

export function fetchRecentlyAddedEntriesResponse(
  recentlyAddedEntries: EntryDataModel,
): EntryActionTypes {
  return {
    type: FETCH_RECENTLY_ADDED_ENTRIES_RESPONSE,
    payload: recentlyAddedEntries,
  };
}

export function fetchRecentlyAddedEntriesFailure(): EntryActionTypes {
  return {
    type: FETCH_RECENTLY_ADDED_ENTRIES_FAILURE,
    payload: null,
  };
}

export function fetchRecentlyAddedEntriesRequest(): EntryActionTypes {
  return {
    type: FETCH_RECENTLY_ADDED_ENTRIES_REQUEST,
    payload: null,
  };
}

export function fetchRecentlyReusedEntriesResponse(
  recentlyReusedEntries: EntryDataModel,
): EntryActionTypes {
  return {
    type: FETCH_RECENTLY_REUSED_ENTRIES_RESPONSE,
    payload: recentlyReusedEntries,
  };
}

export function fetchRecentlyReusedEntriesFailure(): EntryActionTypes {
  return {
    type: FETCH_RECENTLY_REUSED_ENTRIES_FAILURE,
    payload: null,
  };
}

export function fetchRecentlyReusedEntriesRequest(): EntryActionTypes {
  return {
    type: FETCH_RECENTLY_REUSED_ENTRIES_REQUEST,
    payload: null,
  };
}

export function fetchPopularEntriesResponse(
  popularEntries: EntryDataModel,
): EntryActionTypes {
  return {
    type: FETCH_POPULAR_ENTRIES_RESPONSE,
    payload: popularEntries,
  };
}

export function fetchPopularEntriesFailure(): EntryActionTypes {
  return {
    type: FETCH_POPULAR_ENTRIES_FAILURE,
    payload: null,
  };
}

export function fetchPopularEntriesRequest(): EntryActionTypes {
  return {
    type: FETCH_POPULAR_ENTRIES_REQUEST,
    payload: null,
  };
}

export function fetchEntryResponse(
  entries: Entry,
): EntryActionTypes {
  return {
    type: FETCH_ENTRY_RESPONSE,
    payload: entries,
  };
}

export function fetchEntryMatchesResponse(
  matches: EntryMatchDataModel,
): EntryActionTypes {
  return {
    type: FETCH_ENTRY_MATCHES_RESPONSE,
    payload: matches,
  };
}

export function fetchEntryFailure(): EntryActionTypes {
  return {
    type: FETCH_ENTRY_FAILURE,
    payload: null,
  };
}


export function postEntryRequest(): EntryActionTypes {
  return {
    type: POST_ENTRY_REQUEST,
    payload: null,
  };
}

export function postEntryResponse(): EntryActionTypes {
  return {
    type: POST_ENTRY_RESPONSE,
    payload: null,
  };
}

export function postEntryFailure(error: string, validationErrors: any): EntryActionTypes {
  return {
    type: POST_ENTRY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveEntry = (id: string, visibilityPublic?: boolean):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryRequest());
    const asyncResp: any = await getEntry(id, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchEntryResponse(asyncResp.data));
    } else {
      await dispatch(fetchEntryFailure());
    }
  };

export const fetchEntryMatches = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryRequest());
    const asyncResp: any = await matchEntry(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryMatchesResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFailure());
    }
  };

export const fetchEntriesByMarketplace = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryRequest());
    const asyncResp: any = await getEntriesByMarketplace(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFailure());
    }
  };

export const fetchEntryIdsByMarketplace = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryRequest());
    const asyncResp: any = await getEntriesByMarketplace(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchMarketplaceEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFailure());
    }
  };

export const fetchEntryByShortcode = (groupShortcode: string, clientShortcode: string, id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryRequest());
    const asyncResp: any = await getEntryByShortcode(groupShortcode, clientShortcode, id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryResponse(asyncResp.data));
    } else {
      await dispatch(fetchEntryFailure());
    }
  };

export const fetchEntryByShortcodeGuest = (id: string, groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryRequest());
    const asyncResp: any = await getGuestEntryByShortcode(id, groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchEntryResponse(asyncResp.data));
    } else {
      await dispatch(fetchEntryFailure());
    }
  };

export const fetchEntries = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntriesRequest());
    const asyncResp: any = await getEntries(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchEntriesFailure());
    }
  };

export const fetchCreateOfferEntries = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntriesRequest());
    const asyncResp: any = await getCreateOfferEntries(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchEntriesFailure());
    }
  };

export const fetchRecentlyAddedEntries = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchRecentlyAddedEntriesRequest());
    const asyncResp: any = await getNewEntries(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchRecentlyAddedEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchRecentlyAddedEntriesFailure());
    }
  };

export const fetchRecentlyReusedEntries = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchRecentlyReusedEntriesRequest());
    const asyncResp: any = await getReusedEntries(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchRecentlyReusedEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchRecentlyReusedEntriesFailure());
    }
  };

export const fetchPopularEntries = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPopularEntriesRequest());
    const asyncResp: any = await getPublicEntries(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchPopularEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchPopularEntriesFailure());
    }
  };

export const fetchEntriesByShortcodes = (groupShortcode: string, clientShortcode: string, formId : string | null = null):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntriesRequest());
    const asyncResp: any = await getEntriesByShortcodes(groupShortcode, clientShortcode, formId);
    if (asyncResp?.success) {
      await dispatch(fetchEntriesResponse(asyncResp));
    } else {
      await dispatch(fetchEntriesFailure());
    }
  };

export const storeEntry = async (id: string) => {
  try {
    await archiveEntry(id);
  } catch (error) {
    throw error;
  }
};

export const activateEntry = async (id: string) => {
  try {
    await unarchiveEntry(id);
  } catch (error) {
    throw error;
  }
};

export const createEntry = (
  groupId: string, clientId: string, formId: string, assetId: string, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await postEntry(groupId, clientId, formId, assetId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const createEntryWithFields = (
  groupId: string, clientId: string, formId: string, assetId: string, title: string, description: string, active: boolean, fields: any[], fromEntryIds: any[], toEntryIds: any[], tags?: any[],
  newTags?: { value: string, type: string }[],
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await postEntryWithFields(groupId,
      clientId, formId, assetId, title, description, active,
      fields, fromEntryIds, toEntryIds, tags, newTags);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }

  };

export const duplicateEntryWithFields = (
  entryId: string, numberOfDuplicates: number, startingNumber?: number,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await postDuplicateEntry(entryId, numberOfDuplicates, startingNumber);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateEntryWithFields = (
  entryId: string, title:string, description: string, assetId: string, active: boolean, fields: any[], fromEntryIds: any[], toEntryIds: any[],  tagsId: any[],
  newTags?: { value: string, type: string }[], datatableParams?: DataTableParamsModel,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await putEntryWithFields(entryId,  title, description, assetId, active, fields, fromEntryIds, toEntryIds,  tagsId, newTags, datatableParams);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateEntry = (
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  assetId: string,
  title: string,
  description: string,
  active: boolean,
  notes?: string, 
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await putEntry(id, groupId, clientId, formId, assetId, title, description, active, notes);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateEntryFromQR = (
  id: string,
  groupId: string,
  clientId: string,
  formId: string,
  assetId: string,
  title: string,
  description: string,
  active: boolean,
  notes?: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await putEntry(id, groupId, clientId, formId, assetId, title, description, active, notes);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
      await dispatch(fetchEntryTimelineByShortcodes('mqUFWBvNwawg', '5z2msezyfuev', id));
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateEntryStatus = (
  id: string | undefined,
  status: string,
  formId: string,
  updateActiveEntry?: boolean,

):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryRequest());
    const asyncResp: any = await putEntryStatus(id, status);
    if (asyncResp?.success) {
      await dispatch(postEntryResponse());
      if (updateActiveEntry && id) {
        await dispatch(fetchActiveEntry(id));
      } else {
        await dispatch(fetchEntries({ searchField: 'formId', searchWord: formId }));
      }
    } else {
      await dispatch(postEntryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };