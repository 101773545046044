import { ApiDataModel, FormError } from './Common';

export const FETCH_OFFER_ITEMS_REQUEST = 'FETCH_OFFER_ITEMS_REQUEST';
export const FETCH_OFFER_ITEMS_RESPONSE = 'FETCH_OFFER_ITEMS_RESPONSE';
export const FETCH_OFFER_ITEMS_FAILURE = 'FETCH_OFFER_ITEMS_FAILURE';

export const FETCH_OFFER_ITEM_REQUEST = 'FETCH_OFFER_ITEM_REQUEST';
export const FETCH_OFFER_ITEM_RESPONSE = 'FETCH_OFFER_ITEM_RESPONSE';
export const FETCH_OFFER_ITEM_FAILURE = 'FETCH_OFFER_ITEM_FAILURE';

export const POST_OFFER_ITEM_REQUEST = 'POST_OFFER_ITEM_REQUEST';
export const POST_OFFER_ITEM_RESPONSE = 'POST_OFFER_ITEM_RESPONSE';
export const POST_OFFER_ITEM_FAILURE = 'POST_OFFER_ITEM_FAILURE';

export interface OfferItem {
  id: string;
  offerId: string;
  entryId: string;
  quantity: number;
  entryTitle: string;
  title: string;
  minRate: number;
  maxRate: number;
  actualRate: number;
  statuses: string[];
  details: string | null;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface OfferItemDataModel extends Omit<ApiDataModel, 'data'> {
  data: OfferItem[];
}

export interface OfferItemState {
  offerItems: OfferItemDataModel | null,
  activeOfferItem: OfferItem | null,
  isFetchingOfferItems: boolean;
  fetchingOfferItemsFailed: boolean;
  postingOfferItemFailed?: boolean;
  isPostingOfferItem: boolean;
  postingOfferItemValidationErrors: FormError[] | null;
  postingOfferItemError: string | null;
}

interface FetchOfferItemsRequestAction {
  type: typeof FETCH_OFFER_ITEMS_REQUEST;
  payload: null;
}

interface FetchOfferItemsResponseAction {
  type: typeof FETCH_OFFER_ITEMS_RESPONSE;
  payload: OfferItemDataModel;
}

interface FetchOfferItemsFailureAction {
  type: typeof FETCH_OFFER_ITEMS_FAILURE;
  payload: null;
}

interface FetchOfferItemRequestAction {
  type: typeof FETCH_OFFER_ITEM_REQUEST;
  payload: null;
}

interface FetchOfferItemResponseAction {
  type: typeof FETCH_OFFER_ITEM_RESPONSE;
  payload: OfferItem;
}

interface FetchOfferItemFailureAction {
  type: typeof FETCH_OFFER_ITEM_FAILURE;
  payload: null;
}

interface PostOfferItemRequestAction {
  type: typeof POST_OFFER_ITEM_REQUEST;
  payload: null;
}

interface PostOfferItemResponseAction {
  type: typeof POST_OFFER_ITEM_RESPONSE;
  payload: null;
}

interface PostOfferItemFailureAction {
  type: typeof POST_OFFER_ITEM_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type OfferItemActionTypes =
    | FetchOfferItemsRequestAction
    | FetchOfferItemsResponseAction
    | FetchOfferItemsFailureAction
    | PostOfferItemRequestAction
    | PostOfferItemResponseAction
    | PostOfferItemFailureAction
    | FetchOfferItemRequestAction
    | FetchOfferItemResponseAction
    | FetchOfferItemFailureAction;
