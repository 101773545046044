import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router from '../Router';
import { formatUrl, getActiveClient, getToken, getUserToken } from '../../helpers/functions';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import { fetchViews, fetchViewsByShortcodes } from '../../actions/View';
import {
  fetchConfigurationByClientId,
  fetchConfigurationByLogin,
  fetchConfigurationByShortcode,
} from '../../actions/Configuration';
import { fetchLoggedInUser } from '../../actions/Auth';
import useShortcodes from '../../hooks/ShortcodeHook';
import { getLoggedInRole, getLoggedInUser } from '../../selectors/Auth';
import { fetchAssets } from '../../actions/Asset';
import { Helmet } from 'react-helmet';
import { getActiveConfiguration } from '../../selectors/Configuration';
import usePageTracking from '../../hooks/usePageTracking';
import { useEnvironment } from '../../hooks/useEnvironment';
import { DataTableParamsModel } from '../../types/Common';

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentPage = location.pathname;
  const isDevEnv = useEnvironment();
  // @ts-ignore
  const resetToken = useRouteMatch(c.APP_ROUTES.RESET_PASSWORD)?.params.id;
  // @ts-ignore
  const entryRender = useRouteMatch(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC)?.params.id;
  // @ts-ignore
  const staticView = useRouteMatch(c.APP_ROUTES.STATIC_PAGE_VIEW)?.params.pageKey;
  // @ts-ignore
  const scannedQRuuid = useRouteMatch(c.APP_ROUTES.SHIPMENT_JOURNEY)?.params.id;
  const client = getActiveClient();

  const {
    isUsingShortcodes,
    groupShortcode,
    clientShortcode,
  } = useShortcodes(c.APP_ROUTES.DASHBOARD_SHORTCODE, c.APP_ROUTES.DASHBOARD);
  const loggedInUser = useSelector(getLoggedInUser);
  const loggedInRole = useSelector(getLoggedInRole);
  const configuration = useSelector(getActiveConfiguration);

  useEffect(() => {
    if (getToken()) {
      dispatch(fetchLoggedInUser());
    }
  }, [dispatch]);

  const allowedUrls = [
    c.APP_ROUTES.USER_REGISTER,
    c.APP_ROUTES.FORGOT_PASSWORD,
    formatUrl(c.APP_ROUTES.RESET_PASSWORD, resetToken),
    c.APP_ROUTES.USER_PENDING_VERIFICATION,
    c.APP_ROUTES.REGISTER_LEAD,
    c.APP_ROUTES.USER_LOGIN,
    c.APP_ROUTES.SEARCH_RESULT,
    c.APP_ROUTES.RETURN,
    c.APP_ROUTES.STATIC_PAGE_VIEW,
    formatUrl(c.APP_ROUTES.STATIC_PAGE_VIEW, staticView),
    formatUrl(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC, entryRender),
    formatUrl(c.APP_ROUTES.SHIPMENT_JOURNEY, scannedQRuuid),
  ];

  useEffect(() => {
    if ((!getUserToken() && !isUsingShortcodes) && !allowedUrls.includes(currentPage)) {
      history.push(c.APP_ROUTES.LANDING_PAGE);
    }
  }, [currentPage, isUsingShortcodes, groupShortcode, clientShortcode]);

  useEffect(() => {
    if (getUserToken() && loggedInRole) {
      const dataTableParams1: DataTableParamsModel = {
        sortDirection: 'desc',
        paging: '100',
        searchField: 'clientId',
        searchWord: client.id,
      };
      dispatch(fetchViews(dataTableParams1));
      dispatch(fetchAssets({ baseSearch: { 'clientId': client.id } }));
      if (client.id && loggedInUser?.clients?.some(existingClient => existingClient.clientId === client.id)) {
        dispatch(fetchConfigurationByClientId(client.id));
      } else {
        dispatch(fetchConfigurationByLogin(loggedInRole.id));
      }
    } else if (isUsingShortcodes && groupShortcode && clientShortcode){
      dispatch(fetchConfigurationByShortcode(groupShortcode, clientShortcode));
      dispatch(fetchViewsByShortcodes(groupShortcode, clientShortcode));
    }
  }, [loggedInUser, loggedInRole]);

  const faviconUrl = configuration?.favicon ? `${c.API_ENDPOINTS.ASSET_FILE}/${configuration?.favicon}` : '/assets/EME-favicon.ico';

  if (!isDevEnv){
    usePageTracking(loggedInUser?.id);
  }

  return (
      <div className="app">
        <Helmet>
          <link rel="icon" href={faviconUrl} />
        </Helmet>
        <Router/>
      </div>
  );
}

export default App;