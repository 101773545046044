import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_MARKETPLACES_REQUEST,
  FETCH_MARKETPLACES_RESPONSE,
  FETCH_MARKETPLACES_DATATABLE_RESPONSE,
  FETCH_MARKETPLACES_FAILURE,
  FETCH_MARKETPLACE_REQUEST,
  FETCH_MARKETPLACE_RESPONSE,
  FETCH_MARKETPLACE_FAILURE,
  POST_MARKETPLACE_FAILURE,
  POST_MARKETPLACE_REQUEST,
  POST_MARKETPLACE_RESPONSE,
  MarketplaceActionTypes, MarketplaceDataModel, Marketplace,
} from '../types/Marketplace';

import { RootState } from '../store/configureStore';

import {
  archiveMarketplace,
  unarchiveMarketplace,
  getMarketplace,
  getMarketplaces,
  getMarketplacesPlusPublic,
  postMarketplace, postMarketplaceWithItems,
  putMarketplace,
} from '../api/Marketplace';
import { formatUrl } from '../helpers/functions';
import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function fetchMarketplacesRequest(): MarketplaceActionTypes {
  return {
    type: FETCH_MARKETPLACES_REQUEST,
    payload: null,
  };
}

export function fetchMarketplacesResponse(
  marketplaces: MarketplaceDataModel,
): MarketplaceActionTypes {
  return {
    type: FETCH_MARKETPLACES_RESPONSE,
    payload: marketplaces,
  };
}

export function fetchMarketplacesDatatableResponse(
  marketplacesDatatable: MarketplaceDataModel,
): MarketplaceActionTypes {
  return {
    type: FETCH_MARKETPLACES_DATATABLE_RESPONSE,
    payload: marketplacesDatatable,
  };
}

export function fetchMarketplacesFailure(): MarketplaceActionTypes {
  return {
    type: FETCH_MARKETPLACES_FAILURE,
    payload: null,
  };
}

export function fetchMarketplaceRequest(): MarketplaceActionTypes {
  return {
    type: FETCH_MARKETPLACE_REQUEST,
    payload: null,
  };
}

export function fetchMarketplaceResponse(
  marketplace: Marketplace,
): MarketplaceActionTypes {
  return {
    type: FETCH_MARKETPLACE_RESPONSE,
    payload: marketplace,
  };
}

export function fetchMarketplaceFailure(): MarketplaceActionTypes {
  return {
    type: FETCH_MARKETPLACE_FAILURE,
    payload: null,
  };
}


export function postMarketplaceRequest(): MarketplaceActionTypes {
  return {
    type: POST_MARKETPLACE_REQUEST,
    payload: null,
  };
}

export function postMarketplaceResponse(data?: { marketplace?: { id?: string } }): MarketplaceActionTypes {
  return {
    type: POST_MARKETPLACE_RESPONSE,
    payload: data?.marketplace?.id || null, //for redirecting at the end of createMarketplaceWithItems
  };
}

export function postMarketplaceFailure(error: string, validationErrors: any): MarketplaceActionTypes {
  return {
    type: POST_MARKETPLACE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveMarketplace = (id: string, groupShortcode?: string, clientShortcode?: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMarketplaceRequest());
    const asyncResp: any = await getMarketplace(id, groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchMarketplaceResponse(asyncResp.data));
    } else {
      await dispatch(fetchMarketplaceFailure());
    }
  };

export const fetchMarketplaces = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMarketplacesRequest());
    const asyncResp: any = await getMarketplaces(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchMarketplacesDatatableResponse(asyncResp));
    } else {
      await dispatch(fetchMarketplacesFailure());
    }
  };

export const fetchMarketplacesPlusPublic = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchMarketplacesRequest());
    const asyncResp: any = await getMarketplacesPlusPublic(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchMarketplacesResponse(asyncResp));
    } else {
      await dispatch(fetchMarketplacesFailure());
    }
  };

export const storeMarketplace = async (id: string) => {
  try {
    await archiveMarketplace(id);
  } catch (error) {
    throw error;
  }
};

export const activateMarketplace = async (id: string) => {
  try {
    await unarchiveMarketplace(id);
  } catch (error) {
    throw error;
  }
};

export const createMarketplace = (
  groupId: string, clientId: string, title: string, description: string, asset: string, active: boolean, visibility: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMarketplaceRequest());
    // @ts-ignore
    const asyncResp: any = await postMarketplace(groupId, clientId, title, description, asset, active, visibility);
    if (asyncResp?.success) {
      await dispatch(postMarketplaceResponse());
    } else {
      await dispatch(postMarketplaceFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const createMarketplaceWithItems = (
  marketplaceData: Object,
  selectedItems: Object,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMarketplaceRequest());
    const asyncResp: any = await postMarketplaceWithItems(marketplaceData, selectedItems);
    if (asyncResp?.success) {
      const marketplaceId = asyncResp?.data?.marketplace?.id || null;
      await dispatch(postMarketplaceResponse({ marketplace: { id: marketplaceId } }));
    } else {
      await dispatch(postMarketplaceFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const createMarketplaceFromSteps = (
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  asset: string,
  active: boolean,
  visibility: string,
  history: any,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMarketplaceRequest());
    // @ts-ignore
    const asyncResp: any = await postMarketplace(groupId, clientId, title, description, asset, active, visibility);
    if (asyncResp?.success) {
      await dispatch(postMarketplaceResponse());
      history.push(formatUrl(c.APP_ROUTES.MARKETPLACE_CREATE_STEP_TWO, asyncResp?.data.id));
    } else {
      await dispatch(postMarketplaceFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateMarketplace = (
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  asset: string,
  active: boolean | null,
  visibility: string,

):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMarketplaceRequest());
    // @ts-ignore
    const asyncResp: any = await putMarketplace(id, groupId, clientId, title, description, asset, active, visibility);
    if (asyncResp?.success) {
      await dispatch(postMarketplaceResponse());
    } else {
      await dispatch(postMarketplaceFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


export const updateMarketplaceClientAdmin = (
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  asset: string,
  active: boolean | null,
  visibility: string,
  searchWord?: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postMarketplaceRequest());
    // @ts-ignore
    const asyncResp: any = await putMarketplace(id, groupId, clientId, title, description, asset, active, visibility);
    if (asyncResp?.success) {
      await dispatch(postMarketplaceResponse());
      await dispatch(fetchMarketplacesPlusPublic({ baseSearch: { 'all': searchWord } }));
    } else {
      await dispatch(postMarketplaceFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


