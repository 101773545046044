import React from 'react';
import './ButtonToggler.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageButton from '../ImageButton';


type Props = {
  title1: string;
  title2: string;
  onClick?: () => void;
  grid?: boolean;
  img1?: string;
  img2?: string;
  bold?: boolean | undefined;
};

const ButtonToggler = (props: Props) => {
  const {
    title1 = '',
    title2 = '',
    grid,
    onClick,
    img1 = '',
    img2 = '',
  } = props;


  return (
        <div className="d-flex gap-3 buttonToggler">
            <div className={grid ? ' left' : 'left-red' }>
                <ImageButton label={title1} bold iconSrc={img1} onClick={grid ? undefined : onClick}/>
            </div>
            <div className={grid ? 'right-red' : 'right'}>
                <div>
                    <ImageButton label={title2} bold iconSrc={img2} onClick={!grid ? undefined : onClick}/>
                </div>
            </div>
        </div>
  );
};

export default ButtonToggler;
