import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getEntryFields, isFetchingEntryFields } from '../../../selectors/EntryField';
import { activateEntryField, fetchEntryFields, storeEntryField } from '../../../actions/EntryField';
import { EntryField } from '../../../types/EntryField';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const EntryFieldDataTable = () => {

  const entryFields = useSelector(getEntryFields);
  const isLoading = useSelector(isFetchingEntryFields);
  const [toggle, setToggle] = useState(false);
  const [entryField, setActiveEntryField] = useState<EntryField | null>();
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ENTRY_FIELD_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_FIELD_UPDATE, row.id));
  };

  const onArchive = (row: EntryField) => {
    setActiveEntryField(row);
    setToggle(true);
  };

  const onUnArchive = (row: EntryField) => {
    setActiveEntryField(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (entryField?.id) {
      if (unArchiveToggle){
        await activateEntryField(entryField?.id);
      } else {
        await storeEntryField(entryField?.id);
      }
      setRefetch(!refetch);
    }
    setActiveEntryField(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };


  const config = {
    columns: [
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'fieldTitle', label: 'Field', mobileFriendly: true },
      { key: 'order', label: 'Order', mobileFriendly: true },
      { key: 'value', label: 'Value', mobileFriendly: true },
      { key: 'created', label: 'Created', mobileFriendly: true },
      { key: 'updated', label: 'Updated', mobileFriendly: true },

    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };


  return (
      <View title="Entry Fields" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchEntryFields} data={entryFields?.data || []}
                 isLoading={isLoading} paging={ entryFields?.paging || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
    </View>
  );
};

export default EntryFieldDataTable;

