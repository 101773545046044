import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getFields(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELD,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postField(
  groupId: string,
  clientId: string,
  categoryId: string,
  fieldTypeId: string,
  title: string,
  description: string,
  active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELD,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        categoryId,
        fieldTypeId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putField(
  id: string,
  groupId: string,
  clientId: string,
  categoryId: string,
  fieldTypeId: string,
  title: string,
  description: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        categoryId,
        fieldTypeId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveField(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
