import React from 'react';
import './EntryLegalDisclaimer.scss';

const LegalDisclaimer = () => {
  return (
        <div className="legal-disclaimer">
            <p>
                Seek your own legal advice with regard to your liability. EME is providing a platform and is expressly not providing legal advice to the users of this platform.
            </p>
            <p>
                Free goods: Donor’s liability to the recipient with regard to the products for every kind of liability arising under or in connection with the donation including liability in contract, tort (including negligence), misrepresentation, restitution or otherwise is excluded to the greatest extent legally possible.
            </p>
            <p>
                Paid goods: Subject to the remainder of this provision, seller&apos;s total liability on whatever legal ground to the buyer shall not exceed the sale price. The following types of losses are wholly excluded: (i) loss of profits, (ii) loss of sales or business, (iii) loss of agreements or contracts, (iv) loss of anticipating savings, (v) loss of or damage to goodwill and (vi) indirect or consequential loss. Nothing in this provision excludes or limits any liability that legally cannot be limited.
            </p>
        </div>
  );
};

export default LegalDisclaimer;
