import React, { useEffect, useState } from 'react';
import { AuctionStatus, CardType, EntryStatus, MarketplaceStatus, shareableStatuses } from '../../helpers/constants';
import { formatTime } from '../../helpers/functions';
import { utcToZonedTime } from 'date-fns-tz';
import Button from '../Button';
import gallery from '../AssetGallery/AssetGallery';
import './Card.scss';
import CheckBox from '../CheckBox';
import ReadMoreLess from '../ReadMoreLess';

type Props = {
  cardId: number | string;
  title: string;
  description?: string;
  imageUrl?: string;
  onClick:() => void;
  onShare?:() => void;
  onStatusClick?:() => void;
  onAuctionStatusClick?:() => void;
  cardType?: CardType;
  status?: MarketplaceStatus | AuctionStatus | EntryStatus ;
  auctionStatus?: string;
  secondaryLogo?: string;
  linkName?: string;
  metaText?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  linkTitle?: string;
  onLink?:() => void;
  onEdit?:() => void;
  highestBid?: string;
  isSelected?: boolean;
  selectable?: boolean;
  itemId?: string;
  onSelect?: (itemId: string) => void;
  children?: React.ReactNode;
  isActive?: boolean;
  customStyle?: React.CSSProperties;
};


const Card = (props: Props) => {
  const { selectable = false, isSelected = false, isActive = false, imageUrl = '', customStyle } = props;
  const [live, setLive] = useState('Unavailable');
  const [time, setTime] = useState('0');
  const isAuctionMarketplaceCard = props.cardType === CardType.MARKETPLACE || props.cardType === CardType.AUCTION;
  const isAuctionEntryCard = (props.startDateTime && props.endDateTime);
  const convertedDateTime = utcToZonedTime(new Date().toISOString(), 'UTC');
  const currentTimestamp = convertedDateTime.getTime();
  const startTimestamp = props.startDateTime?.getTime();
  const endTimestamp = props.endDateTime?.getTime();


  const toggleSelection = () => {
    if (!props.itemId || !props.onSelect){
      return;
    }
    props?.onSelect(props.itemId);
  };


  const isLiveOrStatus = ()=>{
    if (props.startDateTime && props.endDateTime){
      if (live){
        return live ;
      } else {
        return 'Unavailable';
      }
    } else {
      return props.status;
    }
  };

  useEffect(() => {
    if (startTimestamp && endTimestamp) {

      if (startTimestamp > currentTimestamp && endTimestamp > currentTimestamp){
        setLive('Coming soon');
      } else if (currentTimestamp >= startTimestamp && currentTimestamp <= endTimestamp) {
        setLive('Live');
      } else {
        setLive('Concluded');
      }
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if ( endTimestamp) {
        const cDate = utcToZonedTime(new Date().toISOString(), 'UTC');
        const cTime = cDate.getTime();
        setTime(formatTime(endTimestamp - cTime));
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const isShareable = props.status ? shareableStatuses.includes(props.status) : false;

  return (
      <div className={`${imageUrl ? 'card' : 'card-reduced'} ${isActive ? ' selected' : ''}`} style={{ ...customStyle }}> {/* Apply active class if isActive */}

            <div className='card__img__container'>
              {imageUrl && <img loading="lazy" src={props.imageUrl} alt="" className='card__img' />}
              <div className ={ props.linkTitle ? 'card__edit__container hover-button-down' : 'card__edit__container' }>
                { props.onEdit  && <div className ='card__edit' onClick={props.onEdit}>
                  <img src={gallery.editImg} alt="" className='card__edit__img' />
                </div>}
              {isShareable && <div className={(props.cardType === CardType.MARKETPLACE || props.cardType === CardType.AUCTION) ? 'card__share-alt' : 'card__share'} onClick={props.onShare}>
                <img src={gallery.shareImgBlueBG} alt="" className='card__share__img' />
              </div>}
              </div>
              {!imageUrl && <div className="card-reduced__button">
                <Button title="View" onClick={props.onClick} />
              </div>}
              { selectable && <div className ='card__select'>
                <CheckBox type={'checkbox'} checked={isSelected} onChange={toggleSelection}/>
              </div>}
                { props.linkTitle && <div className='card__link' onClick={props.onLink}>
                    {props.linkTitle}
                </div>}
                { props.status && <div className={props.onStatusClick ?  `card__status card__status-${props.status.toLowerCase().replace(' ', '-')} card__status-hover` : `card__status card__status-${props.status.toLowerCase().replace(' ', '-')}`} onClick={props.onStatusClick} >
                     {isLiveOrStatus()}
                </div>}
              { props.auctionStatus && <div
                  className={
                    props.onAuctionStatusClick
                      ? `card__status card__status-auction  card__status-auction-${props.auctionStatus.toLowerCase()} card__status-auction-hover`
                      : `card__status card__status-auction  card__status-auction-${props.auctionStatus.toLowerCase()}`
                  } onClick={props.onAuctionStatusClick} >
                {props.auctionStatus}
              </div>}
                { props.secondaryLogo && <div className='card__secondary-logo'>
                    <img loading="lazy" src={props.secondaryLogo} alt="" className='card__secondary-logo__img' />
                </div>}
              {props.highestBid !== null && props.highestBid !== undefined && (
                  <div className='card__highest-bid'>
                    {live === 'Live' ? `Bid: ${props.highestBid} GBP` : live}
                  </div>
              )}
              {imageUrl && <div className="card__button">
                <Button title="View" disabled={live === 'Coming soon' || props.status === 'Coming Soon'} onClick={props.onClick}/>
              </div>}
            </div>
            <div className={isAuctionMarketplaceCard ? 'card__details' : !imageUrl ? 'card-reduced__details' : 'card__details card__details--basic'}>
                <div className={isAuctionMarketplaceCard ? 'card__left' : 'card__left card__left--basic'}>
                <div className='card__title'>
                    {props.title}
                </div>
                  {props.description && <ReadMoreLess text={props.description} limit={40}/>}
                </div>
                {(props.metaText || isAuctionEntryCard ) &&
                    <div className={isAuctionMarketplaceCard ? 'card__right' : 'card__right card__right--basic'}>
                        { props.metaText &&
                    <div className='card__match'>
                      {props.metaText}
                    </div>}
                        { isAuctionEntryCard  &&
                            <div className={live === 'Live' ? 'card__time' : 'card__time--hidden'}>
                                <div className='card__time__text'>
                                    Time Left: {time}
                                </div>
                            </div>}
                </div>}
            </div>
          <div>
            {props.children && <div className={`card__children ${isActive ? 'selected-children' : ''}`}>
              {props.children}
            </div>}
          </div>
        </div>
  );
};

export default Card;
