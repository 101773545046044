import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getOfferItemStatusCodes, isFetchingOfferItemStatusCodes } from '../../../selectors/OfferItemStatusCode';
import {
  activateOfferItemStatusCode,
  fetchOfferItemStatusCodes,
  storeOfferItemStatusCode,
} from '../../../actions/OfferItemStatusCode';
import { OfferItemStatusCode } from '../../../types/OfferItemStatusCode';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const OfferItemStatusCodeDataTable = () => {

  const offerItemStatusCodes = useSelector(getOfferItemStatusCodes);
  const isLoading = useSelector(isFetchingOfferItemStatusCodes);
  const [toggle, setToggle] = useState(false);
  const [offerItemStatusCode, setActiveOfferItemStatusCode] = useState<OfferItemStatusCode | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.OFFER_ITEM_STATUS_CODE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.OFFER_ITEM_STATUS_CODE_UPDATE, row.id));
  };

  const onArchive = (row: OfferItemStatusCode) => {
    setActiveOfferItemStatusCode(row);
    setToggle(true);
  };

  const onUnArchive = (row: OfferItemStatusCode) => {
    setActiveOfferItemStatusCode(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (offerItemStatusCode?.id) {
      if (unArchiveToggle){
        await activateOfferItemStatusCode(offerItemStatusCode?.id);
      } else {
        await storeOfferItemStatusCode(offerItemStatusCode?.id);
      }
      setRefetch(!refetch);
    }
    setActiveOfferItemStatusCode(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'offerItemDetails', label: 'Details', mobileFriendly: false },
      { key: 'status', label: 'Status', mobileFriendly: false },
      { key: 'notes', label: 'Notes', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Offer Item StatusCodes" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchOfferItemStatusCodes}
                data={offerItemStatusCodes?.data || []}
                isLoading={isLoading}
                paging={offerItemStatusCodes?.paging || []}
                config={config}
                dependencies={refetch}
            />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Offer Item Status Code' : 'Archive Offer Item Status Code'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this Offer Item Status Code?' : 'Are you sure you want to archive this Offer Item Status Code?'}
          </Modal>
        </View>
  );
};

export default OfferItemStatusCodeDataTable;
