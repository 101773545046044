import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getMatch(id: string, dataTableParams?: DataTableParamsModel) {
  const baseUrl = `${c.API_ENDPOINTS.MATCH}`;
  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;
    
  return (
    axios({
      url: `${url}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function makeMatchesByEntry(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.MATCH_ENTRY}/${id}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getMatchesByEntry(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url:`${c.API_ENDPOINTS.ENTRY_MATCHES}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getMatches(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.MATCH,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postMatch(groupId: string, clientId: string, solutionId: string, entryId: string,
  status: string, impact: string, significance: number, reuseHierarchy: number,
  environmentalResponse: string, financialResponse: string, socialResponse: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.MATCH,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        solutionId,
        entryId,
        status,
        impact,
        significance,
        reuseHierarchy,
        environmentalResponse,
        financialResponse,
        socialResponse,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putMatch(
  id: string,
  groupId: string,
  clientId: string,
  solutionId: string,
  entryId: string,
  status: string,
  impact: string,
  significance: number,
  reuseHierarchy: number,
  environmentalResponse: string,
  financialResponse: string,
  socialResponse: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.MATCH}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        solutionId,
        entryId,
        status,
        impact,
        significance,
        reuseHierarchy,
        environmentalResponse,
        financialResponse,
        socialResponse,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveMatch(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.MATCH}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveMatch(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.MATCH}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

