import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getTags, isFetchingTags } from '../../../selectors/Tag';
import { activateTag, fetchTags, storeTag } from '../../../actions/Tag';
import { Tag } from '../../../types/Tag';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const TagDataTable = () => {

  const tags = useSelector(getTags);
  const isLoading = useSelector(isFetchingTags);
  const [toggle, setToggle] = useState(false);
  const [tag, setActiveTag] = useState<Tag | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.TAG_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.TAG_UPDATE, row.id));
  };

  const onArchive = (row: Tag) => {
    setActiveTag(row);
    setToggle(true);
  };

  const onUnArchive = (row: Tag) => {
    setActiveTag(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (tag?.id) {
      if (unArchiveToggle){
        await activateTag(tag?.id);
      } else {
        await storeTag(tag?.id);
      }
      setRefetch(!refetch);
    }
    setActiveTag(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'value', label: 'Value', mobileFriendly: true },
      { key: 'type', label: 'Type', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
      <View title="Tags" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchTags} paging={tags?.paging || []} isLoading={isLoading}  data={tags?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Tag' : 'Archive Tag'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this Tag?' : 'Are you sure you want to archive this Tag?'}
        </Modal>
      </View>
  );
};

export default TagDataTable;

