import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getOfferStatusCodes, isFetchingOfferStatusCodes } from '../../../selectors/OfferStatusCode';
import { activateOfferStatusCode, fetchOfferStatusCodes, storeOfferStatusCode } from '../../../actions/OfferStatusCode';
import { OfferStatusCode } from '../../../types/OfferStatusCode';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const OfferStatusCodeDataTable = () => {

  const offerStatusCodes = useSelector(getOfferStatusCodes);
  const isLoading = useSelector(isFetchingOfferStatusCodes);
  const [toggle, setToggle] = useState(false);
  const [offerStatusCode, setActiveOfferStatusCode] = useState<OfferStatusCode | null>();
  const [refetch, setRefetch] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.OFFER_STATUS_CODE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.OFFER_STATUS_CODE_UPDATE, row.id));
  };

  const onArchive = (row: OfferStatusCode) => {
    setActiveOfferStatusCode(row);
    setToggle(true);
  };

  const onUnArchive = (row: OfferStatusCode) => {
    setActiveOfferStatusCode(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (offerStatusCode?.id) {
      if (unArchiveToggle){
        await activateOfferStatusCode(offerStatusCode?.id);
      } else {
        await storeOfferStatusCode(offerStatusCode?.id);
      }
      setRefetch(!refetch);
    }
    setActiveOfferStatusCode(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'offerNumber', label: 'Offer', mobileFriendly: false },
      { key: 'statusCodeValue', label: 'Offer Code', mobileFriendly: false },
      { key: 'notes', label: 'Notes', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
    hideButton: true,
  };

  return (
        <View title="Offer StatusCodes" onAddNew={onCreate}>
            <DataTable
                fetchFunction={fetchOfferStatusCodes}
                data={offerStatusCodes?.data || []}
                isLoading={isLoading}
                paging={offerStatusCodes?.paging || []}
                config={config}
                dependencies={refetch}
            />
          <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Offer Status Code' : 'Archive Offer Status Code'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
            {unArchiveToggle ? 'Are you sure you want to unarchive this Offer Status Code?' : 'Are you sure you want to archive this Offer Status Code?'}
          </Modal>
        </View>
  );
};

export default OfferStatusCodeDataTable;
