import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_BADGES_REQUEST,
  FETCH_BADGES_RESPONSE,
  FETCH_BADGES_FAILURE,
  FETCH_BADGE_REQUEST,
  FETCH_BADGE_RESPONSE,
  FETCH_BADGE_FAILURE,
  POST_BADGE_FAILURE,
  POST_BADGE_REQUEST,
  POST_BADGE_RESPONSE,
  BadgeActionTypes, BadgeDataModel, Badge,
} from '../types/Badge';

import { RootState } from '../store/configureStore';

import { archiveBadge, unarchiveBadge, getBadge, getBadges, postBadge, putBadge } from '../api/Badge';
import { DataTableParamsModel } from '../types/Common';

export function fetchBadgesRequest(): BadgeActionTypes {
  return {
    type: FETCH_BADGES_REQUEST,
    payload: null,
  };
}

export function fetchBadgesResponse(
  badges: BadgeDataModel,
): BadgeActionTypes {
  return {
    type: FETCH_BADGES_RESPONSE,
    payload: badges,
  };
}

export function fetchBadgesFailure(): BadgeActionTypes {
  return {
    type: FETCH_BADGES_FAILURE,
    payload: null,
  };
}

export function fetchBadgeRequest(): BadgeActionTypes {
  return {
    type: FETCH_BADGE_REQUEST,
    payload: null,
  };
}

export function fetchBadgeResponse(
  badge: Badge,
): BadgeActionTypes {
  return {
    type: FETCH_BADGE_RESPONSE,
    payload: badge,
  };
}

export function fetchBadgeFailure(): BadgeActionTypes {
  return {
    type: FETCH_BADGE_FAILURE,
    payload: null,
  };
}


export function postBadgeRequest(): BadgeActionTypes {
  return {
    type: POST_BADGE_REQUEST,
    payload: null,
  };
}

export function postBadgeResponse(): BadgeActionTypes {
  return {
    type: POST_BADGE_RESPONSE,
    payload: null,
  };
}

export function postBadgeFailure(error: string, validationErrors: any): BadgeActionTypes {
  return {
    type: POST_BADGE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveBadge = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchBadgeRequest());
    const asyncResp: any = await getBadge(id);
    if (asyncResp?.success) {
      await dispatch(fetchBadgeResponse(asyncResp.data));
    } else {
      await dispatch(fetchBadgeFailure());
    }
  };

export const fetchBadges = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchBadgesRequest());
    const asyncResp: any = await getBadges(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchBadgesResponse(asyncResp));
    } else {
      await dispatch(fetchBadgesFailure());
    }
  };

export const storeBadge = async (id: string) => {
  try {
    await archiveBadge(id);
  } catch (error) {
    throw error;
  }
};

export const activateBadge = async (id: string) => {
  try {
    await unarchiveBadge(id);
  } catch (error) {
    throw error;
  }
};

export const createBadge = (
  value: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postBadgeRequest());
    const asyncResp: any = await postBadge(value, active);
    if (asyncResp?.success) {
      await dispatch(postBadgeResponse());
    } else {
      await dispatch(postBadgeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateBadge = (
  id: string,
  value: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postBadgeRequest());
    const asyncResp: any = await putBadge(id, value, active);
    if (asyncResp?.success) {
      await dispatch(postBadgeResponse());
    } else {
      await dispatch(postBadgeFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


