import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getMarketplacesDatatable, isFetchingMarketplaces } from '../../../selectors/Marketplace';
import { activateMarketplace, fetchMarketplaces, storeMarketplace } from '../../../actions/Marketplace';
import { Marketplace } from '../../../types/Marketplace';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const MarketplaceDataTable = () => {

  const marketplaces = useSelector(getMarketplacesDatatable);
  const isLoading = useSelector(isFetchingMarketplaces);
  const [toggle, setToggle] = useState(false);
  const [marketplace, setActiveMarketplace] = useState<Marketplace | null>();
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.MARKETPLACE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.MARKETPLACE_UPDATE, row.id));
  };

  const onArchive = (row: Marketplace) => {
    setActiveMarketplace(row);
    setToggle(true);
  };

  const onUnArchive = (row: Marketplace) => {
    setActiveMarketplace(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (marketplace?.id) {
      if (unArchiveToggle){
        await activateMarketplace(marketplace?.id);
      } else {
        await storeMarketplace(marketplace?.id);
      }
      setRefetch(!refetch);
    }
    setActiveMarketplace(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const handleEditMarketplace = (row: Marketplace) => {
    history.push(formatUrl(c.APP_ROUTES.MARKETPLACE_EDIT_STEP_TWO, row.id));
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'title', label: 'Marketplace Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'visibility', label: 'Visibility', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'add', label: '', onClick: handleEditMarketplace },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };


  return (
      <View title="Marketplaces" onAddNew={onCreate}>
      <DataTable fetchFunction={fetchMarketplaces} paging={marketplaces?.paging || []}
                 isLoading={isLoading}  data={marketplaces?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Entry' : 'Archive Entry'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
    </View>
  );
};

export default MarketplaceDataTable;

