import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getOfferItems(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.OFFER_ITEM,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getOfferItem(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER_ITEM}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postOfferItem(
  offerId: string,
  entryId: string,
  quantity: number,
  minRate: number,
  maxRate: number,
  actualRate: number,
  details: string | null,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.OFFER_ITEM,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        offerId,
        entryId,
        quantity,
        minRate,
        maxRate,
        actualRate,
        details,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putOfferItem(
  id: string,
  offerId: string,
  entryId: string,
  quantity: number,
  minRate: number,
  maxRate: number,
  actualRate: number,
  details: string | null,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER_ITEM}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        offerId,
        entryId,
        quantity,
        minRate,
        maxRate,
        actualRate,
        details,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveOfferItem(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER_ITEM}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveOfferItem(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.OFFER_ITEM}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
